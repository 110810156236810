import React from 'react'
import { ArrowDownOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { AddSquare, Gameboy, Magicpen } from 'iconsax-react'
import './Players.scss'
import GamePlayer from './Player'
import RandomInputItem from './RandomInputItem'

interface Props {
  title?: string,
  type?: string,
  list?: Array<any>,
  playing?: boolean,
  onAddPlayer?: () => void,
  onRemove?: (id: any) => void,
  onRemoveAll?: () => void,
  onUpdate?: (players: any) => void,
}

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const storeGameRole = permission ? permission.find((item: any) => item.function_id === 6) : null

const Players: React.FC<Props> = (props) => {
  const { title, type = 'lucky-game', playing = false, list, onRemoveAll, onAddPlayer, onRemove, onUpdate } = props

  const removeAll = () => {
    if (onRemoveAll) {
      onRemoveAll()
    }
  }

  const onAdd = () => {
    if (onAddPlayer) {
      onAddPlayer()
    }
  }

  const removeItem = (id: any) => {
    if (onRemove) {
      onRemove(id)
    }
  }

  const handleUpdate = (index: number, text: string) => {
    const players: any = list
    players[index].text = text

    if (onUpdate) {
      onUpdate(players)
    }
  }

  return (
    <div className="game-players">
      <div className="game-players__header">
        <Gameboy size={20} color='#999999' variant="Bulk" />
        <Flex align='center' gap={4}>
          <span>{ title }</span>
          <ArrowDownOutlined />
        </Flex>
      </div>

      <div className={`game-players__content ${type}`}>
        {
          type === 'lucky-game' && list?.length
            ? list.map(item => (
                <GamePlayer
                  key={item.id}
                  id={item.id}
                  avatar={item.avatar}
                  name={item.name}
                  disabled={playing}
                  onDelete={() => {
                    if (playing) return
                    removeItem(item.id)
                  }}
                />
              ))
            : null
        }
        {
          type === 'random-game' && list?.length
            ? list.map((item: any, index: number) => (
                <RandomInputItem
                  key={item.id}
                  text={item.text}
                  onDelete={() => removeItem(item.id)}
                  onChange={(text) => handleUpdate(index, text)}
                />
              ))
            : null
        }
      </div>

      <div className="game-players__footer">
        <Button
          icon={<Magicpen size={20} variant="Bulk" />}
          className='rs-button danger-outlined size-sm'
          disabled={playing || list?.length === 0}
          onClick={removeAll}
        >
          Clear all
        </Button>
        <Button
          icon={<AddSquare size={20} variant="Bulk" />}
          className='rs-button light-outlined size-sm'
          disabled={(storeGameRole && storeGameRole.is_create === 0) || playing}
          onClick={onAdd}
        >
          { type === 'lucky-game' ? 'Add player' : 'Add input' }
        </Button>
      </div>
    </div>
  )
}

export default Players
