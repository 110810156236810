import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, DatePicker, Flex, Input, notification, PaginationProps, Table, TableColumnsType } from 'antd'
import { Calendar2, DocumentDownload, Home2, SearchZoomIn1, Warning2 } from 'iconsax-react'
import { ArrowLeftOutlined, ArrowRightOutlined, LineOutlined, } from '@ant-design/icons'
import './staff.scss'
import { mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'
import type { Dayjs } from 'dayjs';
import EditStaffReportInfoPopup from './Components/EditStaffReportInfoPopup'
import { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf('day')
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

interface DataType {
  key: React.Key;
  date: string,
  staff: string,
  staff_warning: boolean,
  start: number,
  add_bank: number,
  match: number,
  payout: number,
  bonus: number,
  extra: number,
  proof: number,
  final: number,
}

const pencil = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1265_4186)"> <path d="M14.167 2.49993C14.3859 2.28106 14.6457 2.10744 14.9317 1.98899C15.2176 1.87054 15.5241 1.80957 15.8337 1.80957C16.1432 1.80957 16.4497 1.87054 16.7357 1.98899C17.0216 2.10744 17.2815 2.28106 17.5003 2.49993C17.7192 2.7188 17.8928 2.97863 18.0113 3.2646C18.1297 3.55057 18.1907 3.85706 18.1907 4.16659C18.1907 4.47612 18.1297 4.78262 18.0113 5.06859C17.8928 5.35455 17.7192 5.61439 17.5003 5.83326L6.25033 17.0833L1.66699 18.3333L2.91699 13.7499L14.167 2.49993Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/> </g> <defs> <clipPath id="clip0_1265_4186"> <rect width="20" height="20" fill="white"/> </clipPath> </defs> </svg>

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const ReportStaff: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState({} as any)
  const [showEditPopup, setShowEditPopup] = useState(false)
  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      key: 'actions',
      fixed: 'left',
      className: 'actions-sticky actions-sticky--left',
      render: (text, record) => (
        <Flex align='center' gap={4}>
          <Button
            icon={pencil}
            className="rs-button edit"
            onClick={() => {
              setSelectedItem(record)
              setShowEditPopup(true)
            }}
          />
        </Flex>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Staff',
      dataIndex: 'staff',
      key: 'staff',
      render: (text, record) => (
        <Flex align='center' gap={8}>
          <span>{ text }</span>
          {
            record.proof !== record.final && <Warning2 size="20" variant="Bulk" className='text-active'/>
          }
        </Flex>
      )
    },
    {
      title: 'Start (USD)',
      dataIndex: 'start',
      key: 'start',
      render: (text, record) => (
        <Flex vertical gap={4}>
          <span className='text-light'>{ numberFormat(text) }</span>
          <span className='text-red-3 title-12'>Add bank&nbsp;{ numberFormat(record.add_bank) }</span>
        </Flex>
      )
    },
    {
      title: 'Match (USD)',
      dataIndex: 'match',
      key: 'match',
      align: 'right',
      render: (text) => (
        <span className='text-light'>{ numberFormat(text) }</span>
      )
    },
    {
      title: 'Pay out (USD)',
      dataIndex: 'payout',
      key: 'payout',
      align: 'right',
      render: (text) => (
        <span className='text-light'>{ numberFormat(text) }</span>
      )
    },
    {
      title: 'Bonus (USD)',
      dataIndex: 'bonus',
      key: 'bonus',
      align: 'right',
      render: (text) => (
        <span className='text-light'>{ numberFormat(text) }</span>
      )
    },
    {
      title: 'Extra (USD)',
      dataIndex: 'extra',
      key: 'extra',
      align: 'right',
      render: (text) => (
        <span className='text-light'>{ numberFormat(text) }</span>
      )
    },
    {
      title: 'Proof (USD)',
      dataIndex: 'proof',
      key: 'proof',
      align: 'right',
      render: (text) => (
        <span className='text-light'>{ numberFormat(text) }</span>
      )
    },
    {
      title: 'Final (USD)',
      dataIndex: 'final',
      key: 'final',
      align: 'right',
      render: (text) => (
        <span className='text-light'>{ numberFormat(text) }</span>
      )
    },
  ]

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    searchKey?: string,
    date_start?: string,
    date_end?: string,
  }>({
    limit: 10,
    page_number: 1,
  })

  const getItems = async () => {
    setTableLoading(true)
    const res = await mixPostV1('/api/webAdmin/gReportStaffByDate.php', params)

    if (res.status === 1) {
      const result:any = []
      res.data.map((item: any) => result.push({
        ...item,
        key: item.info_id,
        date: item.date_report,
        staff: item.staff_name,
        start: item.start_amount,
        add_bank: item.addbank,
        match: item.match_total,
        payout: item.payout_total,
        bonus: item.bonus_amount,
        extra: item.extra_amount,
        proof: item.money_proof,
        final: item.final,
      }))
      setItems({
        ...items,
        list: result,
        total: res.total,
      })
      setTableLoading(false)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchKey: query,
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }

  // On Date Change
  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setParams({
        ...params,
        page_number: 1,
        date_start: dateStrings[0],
        date_end: dateStrings[1],
      })
    } else {
      setParams({
        ...params,
        page_number: 1,
        date_start: '',
        date_end: '',
      })
    }
  };
  // END SEARCH

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }

    try {
      const res = await mixPostV1('/api/webAdmin/gReportStaffByDate.php', customParams)

      if (res.status) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Date': item.date_report,
        'Staff': item.staff_name,
        'Start (USD)': item.start_amount,
        'Add Bank (USD)': item.addbank,
        'Match (USD)': item.match_total,
        'Payout (USD)': item.payout_total,
        'Bonus (USD)': item.bonus_amount,
        'Extra (USD)': item.extra_amount,
        'Proof (USD)': item.money_proof,
        'Final (USD)': item.final,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Staff Report"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout contentClassName="report-staff-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Report', },
            { title: 'Staff', },
          ]}
          title='Staff'
          description=''
        />

        <div className="table-filter-block">
          <Input
            placeholder="Search by name"
            prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
            className="rs-input filter-search-box"
            allowClear
            onPressEnter={(e) => onEnter(e)}
            onChange={(e) => onChange(e)}
          />

          <RangePicker
            allowClear
            className="rs-range-picker"
            suffixIcon={<Calendar2 size={20} />}
            separator={<LineOutlined />}
            placeholder={['From date', 'To date']}
            disabledDate={disabledDate}
            onChange={onRangeChange}
          />

          <Button
            icon={<DocumentDownload size={20} variant="Bulk"/>}
            className="rs-button mis-auto"
            onClick={getItemsByExcel}
          >Export</Button>
        </div>

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table table-text-nowrap match-height"
          loading={tableLoading}
          scroll={{
            x: true,
          }}
          pagination={{
            current: params.page_number,
            pageSize: params.limit,
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTitle: false,
            total: items.total,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />
      </Layout>

      <EditStaffReportInfoPopup
        isShow={showEditPopup}
        item={selectedItem}
        onPopupClose={() => setShowEditPopup(false)}
        onSuccess={getItems}
      />
    </>
  )
}

export default ReportStaff
