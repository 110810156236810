import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import React from 'react'

interface Props {
  title?: string,
  quantity?: number,
  percent?: number,
  type?: string,
  vsText?: string,
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

// SHORTEN NUMBER
const shortenNumber = (number: number = 0) => {
  if (number < 1e3) {
    return number.toString()
  } else if (number < 1e6) {
    return (number / 1e3).toFixed(1) + 'K'
  } else if (number < 1e9) {
    return (number / 1e6).toFixed(1) + 'M'
  } else if (number < 1e12) {
    return (number / 1e9).toFixed(1) + 'B'
  } else {
    return (number / 1e12).toFixed(1) + 'T'
  }
}

const MetricItem: React.FC<Props> = (props) => {
  const { title, quantity, percent, type, vsText = 'yesterday' } = props

  return (
    <div className="metric-item">
      <div className="title">{ title }</div>
      <div className="number">{ shortenNumber(quantity) }</div>
      <div className="compare">
        <div className={`percent-block ${ type === 'increase' ? 'cl-027A48' : '' }`}>
          {/* <div className="icon-svg">
            <ArrowDownOutlined />
          </div> */}
          {
            type === 'increase'
              ? <ArrowUpOutlined />
              : <ArrowDownOutlined />
          }
          <div className="percent">{ percent }%</div>
        </div>
        <div className="text">vs { vsText }</div>
      </div>
    </div>
  )
}

export default MetricItem
