import { Button, TimePicker, Form, FormInstance } from "antd";
import { AddSquare, Magicpen, WristClock } from "iconsax-react";
import { useRef, useEffect, useState } from "react";
const { RangePicker } = TimePicker;
interface Props {
  isSubmit?: any
  matchRules?: any
  changeHourintime?: any
  formFinish?: any
  formError?: any
  createDisabled?: boolean
  editDisabled?: boolean
  deleteDisabled?: boolean
}
const Time: React.FC<Props> = (props) => {
  const { isSubmit, matchRules, changeHourintime, formFinish, formError, createDisabled = false, editDisabled = false,  deleteDisabled = false } = props
  const formRef = useRef<FormInstance>(null)
  const [disabledButton, setDisabledButton] = useState(false)

  useEffect(() => {
    if (matchRules && matchRules.listMatchTime && matchRules.listMatchTime.length > 0) {
      formRef?.current?.setFieldsValue({
        listMatchTime: matchRules.listMatchTime
      })
      setTimesFunc(matchRules.listMatchTime)
    }
  }, [matchRules])

  useEffect(() => {
    if (isSubmit && isSubmit > 0) {
      onSubmitForm()
    }
  }, [isSubmit])

  const onSubmitForm = () => {
    if (formRef && formRef.current) {
      formRef?.current?.submit()
    }
  }

  const onFinish = (values: any) => {
    const temp = [] as any
    if (values.listMatchTime && values.listMatchTime.length > 0) {
      // eslint-disable-next-line array-callback-return
      values.listMatchTime.map((item: any) => {
        temp.push({
          // from_time: item.time[0].format('HH:mm'),
          // to_time: item.time[1].format('HH:mm')
          from_time: item.from_time.format('HH:mm'),
          to_time: item.to_time.format('HH:mm')
        })
        // return true
      })
    }
    formFinish(temp)
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Chuyển đổi giá trị nhập vào thành số
    const value = parseInt(event.target.value)

    if (isNaN(value)) {
      return
    }
    // Kiểm tra nếu giá trị nhập vào nằm trong giới hạn 1 - 24
    if (value < 1) {
      changeHourintime(1)
    } else if (value > 24) {
      changeHourintime(24)
    } else {
      changeHourintime(value)
    }
  }

  const onError = (errors: any) => {
    if (formError) {
      formError(true)
    }
  }

  const [times, setTimes] = useState([] as any)
  const onValuesChange = (changedValues: any, allValues: any) => {
    setTimesFunc(allValues.listMatchTime)
  }

  const setTimesFunc = (data: any) => {
    const timeArr: any = []
    // eslint-disable-next-line array-callback-return
    data.map((item: any, index: number) => {
      if (!item) return false
      const {from_time, to_time} = item
      if (!from_time && !to_time) return false

      timeArr.push({
        from: from_time ? from_time?.format('HH:mm') : '',
        to: to_time ? to_time?.format('HH:mm') : ''
      })
    })
    setTimes(timeArr)
  }

  useEffect(() => {
    const firstShiftFromHour = Number(times[0]?.from?.split(':')[0])
    const lastShiftToTime = times[times.length - 1]?.to
    const lastShiftFromHour = Number(times[times.length - 1]?.from?.split(':')[0])
    const lastShiftToHour = lastShiftToTime ? Number(lastShiftToTime.split(':')[0]) : lastShiftFromHour

    const condition = firstShiftFromHour - lastShiftToHour === 1 || firstShiftFromHour === lastShiftToHour || firstShiftFromHour - lastShiftToHour === -23

    setDisabledButton(condition)
  }, [times])

  const disabledHours = (minHour: number, maxHour?: number) => {
    if (isNaN(minHour)) return []
    const conditionHour = maxHour ? maxHour : 0
    const hours: any = []

    const firstShiftFromHour = Number(times[0]?.from?.split(':')[0])
    const firstShiftFromMinute = Number(times[0]?.from?.split(':')[1])
    const lastShiftToTime = times[times.length - 1]?.to
    const lastShiftFromHour = Number(times[times.length - 1]?.from?.split(':')[0])
    const lastShiftToHour = lastShiftToTime ? Number(lastShiftToTime.split(':')[0]) : lastShiftFromHour
    const condition = lastShiftToHour < firstShiftFromHour
    const limitHour = firstShiftFromMinute === 0 ? firstShiftFromHour : firstShiftFromHour + 1

    if (condition) {
      for (let i = lastShiftToHour - 1; i >= 0; i--) {
        hours.push(i)
      }
      for (let i = limitHour; i < 24; i++) {
        hours.push(i)
      }
    } else {
      for (let i = minHour - 1; i >= conditionHour; i--) {
        hours.push(i)
      }
    }
    return hours
  }

  const disabledMinutes = (selectedHour: number, minHour: any, minMinute: any, isToTime?: boolean) => {
    const minutes: any = []
    const firstShiftHour = Number(times[0]?.from?.split(':')[0])
    const firstShiftMinute = Number(times[0]?.from?.split(':')[1])

    if (selectedHour === minHour) {
      for (let i = minMinute; i >= 0; i--) {
        minutes.push(i)
      }
    }
    if (selectedHour === firstShiftHour && isToTime) {
      for (let i = firstShiftMinute; i < 60; i++) {
        minutes.push(i)
      }
    }
    return minutes
  }

  const disabledFromTime = (minTime: string) => {
    const minHour = Number(minTime?.split(':')[0])
    const minMinute = Number(minTime?.split(':')[1])
    const limitMinHour = minMinute === 59 ? minHour + 1 : minHour

    return {
      disabledHours: () => disabledHours(limitMinHour),
      disabledMinutes: (selectedHour: any) => disabledMinutes(selectedHour, minHour,minMinute)
    }
  }

  const disabledToTime = (minTime: string, maxTime?: string) => {
    const minHour = Number(minTime?.split(':')[0])
    const minMinute = Number(minTime?.split(':')[1])
    const maxHour = Number(maxTime?.split(':')[0])
    const maxMinute = Number(maxTime?.split(':')[1])
    const limitMinHour = minMinute === 59 ? minHour + 1 : minHour
    const limitMaxHour = maxMinute > 0 ? maxHour + 1 : maxHour

    return {
      disabledHours: () => disabledHours(limitMinHour, limitMaxHour),
      disabledMinutes: (selectedHour: any) => disabledMinutes(selectedHour, minHour, minMinute, true)
    }
  }

  return (
    <div className="content collapse-shift">
      <span>
        Once player matched for a first time, the next time starts getting matched
      </span>
      <span className="ctrl-input-number">
        <input
          type="number"
          value={matchRules.hour_in_time}
          className="rs-input"
          disabled={editDisabled}
          onChange={handleChange}
        />
      </span>
      <span>
        hour after it begins.
      </span>

      <div className="always-open mt-16">
        <Form
          ref={formRef}
          name="device-form"
          onFinish={onFinish}
          onFinishFailed={onError}
          autoComplete="off"
          onValuesChange={onValuesChange}
        >
          <Form.List name="listMatchTime">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div className="one-input-time one-2column" key={index}>
                    <div className="ctrl-left full time-ranger">
                      {/* <Form.Item
                        {...restField}
                        name={[name, 'time']}
                        rules={[{ required: true, message: 'Please filled this field' }]}
                      >
                        <RangePicker
                          minuteStep={10}
                          format="HH:mm"
                          placeholder={['From', 'To']}
                          className="t-range-picker-ctrl"
                          suffixIcon={<WristClock size={20} variant="Bulk" />}
                          disabled={editDisabled}
                        />
                      </Form.Item> */}
                      <WristClock size={20} variant="Bulk" />
                      <Form.Item
                        {...restField}
                        name={[name, 'from_time']}
                        rules={[{ required: true, message: 'Please select time' }]}
                      >
                        <TimePicker
                          format="HH:mm"
                          placeholder='From'
                          suffixIcon={null}
                          showNow={false}
                          needConfirm={false}
                          disabledTime={() => disabledFromTime(times[index - 1]?.to)}
                          className='rs-date-picker'
                        />
                      </Form.Item>
                      <div className="horizontal-divider"></div>
                      <Form.Item
                        {...restField}
                        name={[name, 'to_time']}
                        rules={[{ required: true, message: 'Please select time' }]}
                      >
                        <TimePicker
                          format="HH:mm"
                          placeholder='To'
                          suffixIcon={null}
                          showNow={false}
                          needConfirm={false}
                          disabled={!times[index]?.from}
                          disabledTime={() => disabledToTime(times[index]?.from, times[0]?.from)}
                          className='rs-date-picker'
                        />
                      </Form.Item>
                    </div>
                    {/* <div className="ctrl-right">
                      <div
                        className={`right ${index !== 0 ? 'cl-FD6A6A' : ''}`}
                        onClick={() => {
                          if (index !== 0) remove(name)
                        }}
                      >
                        <Magicpen size="20" variant="Bulk" />
                      </div>
                    </div> */}
                    <div className={`right ${index !== 0 ? 'cl-FD6A6A' : ''}`}>
                      <Button
                        disabled={deleteDisabled}
                        icon={<Magicpen size="20" variant="Bulk" color={index !== 0 ? '#FD6A6A' : '#999999'} />}
                        onClick={() => {
                          if (index !== 0) {
                            remove(name)
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    disabled={createDisabled || disabledButton}
                    className='btn-add-new'
                    onClick={() => add()}
                  >
                    <div className="icon-svg"><AddSquare size="20" color="#f9d361" variant="Bulk" /></div>
                    <div className="text">Add new</div>
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          {/* <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </div>
    </div>
  )
}

export default Time
