import React, { useState, useEffect } from 'react'
import SettingLayout from './Components/SettingLayout'
import DeleteModal from '../../components/Modal/DeleteModal'
import { Button, Flex, notification } from 'antd'
import { Card } from 'iconsax-react'
import PaymentMethodGrid from './Components/PaymentMethodGrid'
import PaymentMethodItem from './Components/PaymentMethodItem'
import PaymentMethodAddCard from './Components/PaymentMethodAddCard'
import { mixPostV1 } from '../../services/mixin'

// const items = [
//   { id: 1,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/mastercard.svg', isDefault: true, },
//   { id: 2,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/visa.svg', isDefault: false, },
//   { id: 3,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/mastercard.svg', isDefault: false, },
//   { id: 4,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/mastercard.svg', isDefault: false, },
//   { id: 5,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/visa.svg', isDefault: false, },
//   { id: 6,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/mastercard.svg', isDefault: false, },
//   { id: 7,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/mastercard.svg', isDefault: false, },
//   { id: 8,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/visa.svg', isDefault: false, },
//   { id: 9,name: 'HOANG KIM QUOC', number: '**** **** **** 8976', image: '/images/banks/mastercard.svg', isDefault: false, },
// ]

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const SettingPaymentMethod:React.FC = () => {
  const [showAddCard, setShowAddCard] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()
  const [items, setItems] = useState([] as any)
  let waitx = false
  const getItems = async () => {
    if (waitx) { return }
    waitx = true
    const res = await mixPostV1('/api/webAdmin/gListPaymentCard.php', {})
    waitx = false

    if (res.status === 1) {
      const temp = [] as any
      res.data.map((val: any) => {
        temp.push({
          ...val,
          id: val.card_id,
          name: val.card_name,
          number: `**** **** **** ${val.last_number}`,
          isDefault: val.is_default === 1,
          image: '/images/banks/visa.svg'
        })
        return true
      })
      setItems(temp)
    }
  }
  useEffect(() => { getItems() }, [])

  let waitx2 = false
  const setDefault = async (id: any) => {
    if (waitx2 === true) {
      return
    }
    waitx2 = true
    const params = {
      card_id: id
    } as any
    const res = await mixPostV1('/api/webAdmin/setDefaultPaymentCard.php', params)
    if (res.status === 1) {
      waitx2 = false
      openNotification('success')
      getItems()
    } else {
      waitx2 = false
      openNotification('error', res.message)
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Card added!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      {contextHolder}
      <SettingLayout current="payment-method">
        <Flex vertical gap={18}>
          <Flex gap={12} align='center' justify='space-between'>
            <div className="text-idle font-medium">Payment card</div>
            <Button
              icon={<Card size={20} variant='Bulk' />}
              className='rs-button button-update'
              disabled={settingRole && settingRole.is_create === 0}
              onClick={() => setShowAddCard(true)}
            >Add new card</Button>
          </Flex>

          <PaymentMethodGrid>
            {
              items.length > 0 && items.map((item: any) => (
                <PaymentMethodItem
                  key={item.id}
                  cardName={item.name}
                  cardNumber={item.number}
                  cardLogo={item.image}
                  isDefault={item.isDefault}
                  editDisabled={settingRole && settingRole.is_edit === 0}
                  deleteDisabled={settingRole && settingRole.is_delete === 0}
                  onRemove={() => {
                    setShowDeletePopup(true)
                    setSelectedId(item.id)
                  }}
                  onSetDefault={() => setDefault(item.id)}
                />
              ))
            }
          </PaymentMethodGrid>
        </Flex>

        {/* ADD CARD */}
        <PaymentMethodAddCard
          isShow={showAddCard}
          onPopupClose={() => setShowAddCard(false)}
          onSuccess={() => {
            setShowAddCard(false)
            getItems()
          }}
        />

        {/* REMOVE CARD */}
        <DeleteModal
          isShow={showDeletePopup}
          content='Are you sure you want to delete this card?'
          message='Card removed!'
          yesText='Yes, Delete permanently'
          deleteApi='/api/webAdmin/deleteCardPayment.php'
          deleteId={selectedId}
          deleteKey='card_id'
          onModalClose={() => setShowDeletePopup(false)}
          onModalSubmit={() => {
            getItems()
            setShowDeletePopup(false)
          }}
        />
      </SettingLayout>
    </>
  )
}

export default SettingPaymentMethod
