import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

type dataItemType = {
  name: string,
  value: number,
  color: string,
}

interface Props {
  data?: dataItemType[]
}

const PieChart1: React.FC<Props> = (props) => {
  const { data } = props
  const chartData: dataItemType[] = data ? data : [{ name: 'Women', value: 350, color: '#orange' }, { name: 'Men', value: 300, color: '#blue' },]
  const total = chartData.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.value
  }, 0)

  const renderLegendText = (value: string, entry: any, index: number) => {
    const percentageValue = chartData[index].value ? ((chartData[index].value * 100) / total).toFixed(0) + "%" : '0%'
    return <span>{value} <span className="neutral-gray-400">({percentageValue})</span></span>
  };


  return (
    <div className="arc-card">
      <div className="arc-card__content">
        <ResponsiveContainer height={234}>
          <PieChart
            width={160}
            // height={160}
            className="statistical-pie-chart rs-recharts-tooltip"
            style={{ border: "none" }}
          >
            <defs>
              <linearGradient id="blue" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#EF7B82" stopOpacity={1} />
                <stop offset="100%" stopColor="#f5747d" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="orange" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#6A94FF" stopOpacity={1} />
                <stop offset="100%" stopColor="#5a87fa" stopOpacity={1} />
              </linearGradient>
            </defs>

            <Pie
              data={chartData}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              fill="url(#blue)"
              startAngle={180}
              endAngle={-180}
              paddingAngle={3}
            >
              {
                chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(${entry.color})`} />
                ))
              }
            </Pie>

            <Tooltip />
            <text
              x="27%"
              y="47%"
              textAnchor="middle"
              dominantBaseline="middle"
              className="number"
              fill="#fff"
              style={{ fontSize: "32px" }}
            >
              { total }
            </text>
            <text
              x="27%"
              y="58%"
              textAnchor="middle"
              dominantBaseline="middle"
              className="text"
              fill="#FFFFFF99"
            >
              Members
            </text>
            <Legend verticalAlign="middle" layout="vertical" iconType="square" align="right" formatter={renderLegendText} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PieChart1;
