import React, { useEffect, useState } from 'react'
import SettingLayout from './Components/SettingLayout'
import DeleteModal from '../../components/Modal/DeleteModal'
import { mixGetV1, mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'
import { Button, Flex, Input, notification, PaginationProps, Select, SelectProps, Table, TableColumnsType } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons'
import SettingEditUserPopup from './TabUser/EditUser'
import SettingCreateUserPopup from './TabUser/CreateNewUserPopup'
import { ArrowSquareDown, Crown, DocumentDownload, SearchZoomIn1, UserAdd } from 'iconsax-react'

interface DataType {
  key: React.Key;
  staffId: number,
  staff: string,
  role: string,
  phoneNumber: string,
  email: string
}

const pencil = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1265_4186)"> <path d="M14.167 2.49993C14.3859 2.28106 14.6457 2.10744 14.9317 1.98899C15.2176 1.87054 15.5241 1.80957 15.8337 1.80957C16.1432 1.80957 16.4497 1.87054 16.7357 1.98899C17.0216 2.10744 17.2815 2.28106 17.5003 2.49993C17.7192 2.7188 17.8928 2.97863 18.0113 3.2646C18.1297 3.55057 18.1907 3.85706 18.1907 4.16659C18.1907 4.47612 18.1297 4.78262 18.0113 5.06859C17.8928 5.35455 17.7192 5.61439 17.5003 5.83326L6.25033 17.0833L1.66699 18.3333L2.91699 13.7499L14.167 2.49993Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" /> </g> <defs> <clipPath id="clip0_1265_4186"> <rect width="20" height="20" fill="white" /> </clipPath> </defs> </svg>

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const SettingUserList:React.FC = () => {
  const [showCreateNewUserPopup, setShowCreateNewUserPopup] = useState(false)
  const [showEditStaffPopup, setShowEditStaffPopup] = useState(false)
  const [showDeleteStaffPopup, setShowDeleteStaffPopup] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      key: 'actions',
      fixed: 'left',
      className: 'actions-sticky actions-sticky--left',
      render: (record) => (
        <Flex align='center' gap={4}>
          <Button
            icon={<DeleteOutlined />}
            className="rs-button delete"
            disabled={settingRole && settingRole.is_delete === 0}
            onClick={() => {
              setSelectedId(record.id)
              setShowDeleteStaffPopup(true)
            }}
          />
          <Button
            icon={pencil}
            className="rs-button edit"
            disabled={settingRole && settingRole.is_edit === 0}
            onClick={() => {
              setSelectedId(record.id)
              setShowEditStaffPopup(true)
            }}
          />
        </Flex>
      )
    },
    {
      title: 'User ID',
      dataIndex: 'staffId',
      key: 'staffId',
    },
    {
      title: 'Staff',
      dataIndex: 'staff',
      key: 'staff',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ]

  type LabelRender = SelectProps['labelRender'];
  const customSelectedLabel: LabelRender = (props) => {
    const { label } = props
    if (label) {
      return <Flex align='center' gap={8}><Crown size={20} variant="Bulk" /><span>Role (<span className='text-active'>{label}</span>)</span></Flex>
    }
    return <Flex align='center' gap={8}><Crown size={20} variant="Bulk" /><span>Select role</span></Flex>
  }

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    searchKey?: string,
    role?: any,
  }>({
    limit: 10,
    page_number: 1,
  })

  const getItems = async () => {
    setTableLoading(true)
    const res = await mixPostV1('/api/webAdmin/gListUserByStore.php', params)

    if (res.status === 1) {
      const result:any = []
      res.data.map((item: any) => result.push({
        ...item,
        key: item.id,
        staffId: item.id,
        staff: item.user_name,
        role: item.role_name,
        phoneNumber: item.phone_number,
      }))
      setItems({
        ...items,
        list: result,
        total: res.total,
      })
      setTableLoading(false)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchKey: query
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }
  // END SEARCH

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }

    try {
      const res = await mixPostV1('/api/webAdmin/gListUserByStore.php', customParams)

      if (res.status === 1) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'User ID': item.id,
        'Staff': item.user_name,
        'Staff Role': item.role_name,
        'Phone Number': item.phone_number,
        'Email': item.email,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"User list"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // GET USER ROLES
  const [roles, setRoles] = useState([] as any)
  const getUserRoles = async () => {
    const res = await mixGetV1('/api/webAdmin/gComboRoleUser.php', {})

    if (res.status === 1) {
      const result: any = []

      res.data.map((item: any) => result.push({
        ...item,
        value: item.role_id,
        label: item.role_name,
      }))

      setRoles(result)
    }
  }
  useEffect(() => {
    getUserRoles()
  }, [])
  // END GET USER ROLES

  // ROLE CHANGE
  const onRoleChange = (value: any) => {
    if (value) {
      setParams({
        ...params,
        page_number: 1,
        role: value
      })
    } else {
      setParams({
        ...params,
        page_number: 1,
        role: ''
      })
    }
  }
  // END ROLE CHANGE

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Staff created!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <SettingLayout matchHeight={false} current="user-list">
        <div className="table-filter-block">
          <Input
            placeholder="Search by phone number"
            prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
            allowClear
            className="rs-input table-filter-block__search"
            onPressEnter={(e) => onEnter(e)}
            onChange={(e) => onChange(e)}
          />

          <Select
            defaultValue={-1}
            options={roles}
            placeholder="Select role"
            allowClear
            popupMatchSelectWidth={false}
            suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
            className="rs-select mw-290"
            labelRender={customSelectedLabel}
            onChange={onRoleChange}
          />

          <Button
            icon={<DocumentDownload size={20} variant="Bulk" />}
            className="rs-button mis-auto"
            onClick={getItemsByExcel}
          >Export</Button>

          <Button
            icon={<UserAdd size={20} variant="Bulk" />}
            className="rs-button"
            disabled={settingRole && settingRole.is_create === 0}
            onClick={() => setShowCreateNewUserPopup(true)}
          >Create new user</Button>
        </div>

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table table-text-nowrap match-height"
          loading={tableLoading}
          scroll={{
            x: true,
          }}
          pagination={{
            current: params.page_number,
            pageSize: params.limit,
            position: ['bottomCenter'],
            total: items.total,
            showSizeChanger: true,
            showTitle: false,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />

        <SettingCreateUserPopup
          isShow={showCreateNewUserPopup}
          onPopupClose={() => setShowCreateNewUserPopup(false)}
          onSuccess={getItems}
        />

        <SettingEditUserPopup
          id={selectedId}
          isShow={showEditStaffPopup}
          onPopupClose={() => setShowEditStaffPopup(false)}
          onSuccess={getItems}
        />

        <DeleteModal
          isShow={showDeleteStaffPopup}
          content='Are you sure you want to delete this User?'
          message='User deleted!'
          // onModalClose={() => setShowDeleteStaffPopup(false)}
          // onModalSubmit={() => setShowDeleteStaffPopup(false)}
          deleteApi='/api/webAdmin/deleteAccountUser.php'
          deleteId={selectedId}
          deleteKey='user_id'
          onModalClose={() => setShowDeleteStaffPopup(false)}
          onModalSubmit={() => {
            getItems()
            setShowDeleteStaffPopup(false)
          }}
        />
      </SettingLayout>
    </>
  )
}

export default SettingUserList
