import React from 'react'
import { Button, Flex } from 'antd'
import { Crown1, Trash } from 'iconsax-react'
import './PaymentMethodItem.scss'

interface Props {
  cardName?: string
  cardNumber?: string
  cardLogo?: string
  isDefault?: Boolean
  editDisabled?: boolean
  deleteDisabled?: boolean
  onRemove?: () => void
  onSetDefault?: () => void
}

const PaymentMethodItem:React.FC<Props> = (props) => {
  const { cardName, cardNumber, cardLogo, isDefault = false, editDisabled = false, deleteDisabled = false, onRemove, onSetDefault } = props

  const handleRemove = () => {
    if (onRemove && !deleteDisabled) {
      onRemove()
    }
  }

  const handleSetDefault = () => {
    if (onSetDefault && !editDisabled) {
      onSetDefault()
    }
  }

  return (
    <div className="payment-method-item">
      <div className="info">
        <div className="title">{ cardName }</div>
        <div className="number">{ cardNumber }</div>
      </div>
      <div className="foot">
        <img src={cardLogo} alt='' />
        {
          isDefault && <Flex gap={8} align='center' className='default'><Crown1 variant='Bulk' size={24} /><span>Default</span></Flex>
        }
      </div>
      <div className="controls">
        <Button
          icon={<Trash size={24} variant='Bulk' />}
          className='rs-button danger'
          disabled={deleteDisabled}
          onClick={handleRemove}
        >Remove</Button>
        <Button
          icon={<Crown1 size={24} variant='Bulk' />}
          type='primary'
          className='rs-button button-primary'
          disabled={editDisabled}
          onClick={handleSetDefault}
        >Set as default</Button>
      </div>
    </div>
  )
}

export default PaymentMethodItem
