import React, { useEffect, useRef, useState } from 'react'
import SettingLayout from './Components/SettingLayout'
import { Button, Collapse, CollapseProps, Flex, Form, Select, notification } from 'antd';
import { CalendarAdd, I24Support, TicketStar } from 'iconsax-react';
import Hour from './TabMatchRules/Hour';
import Time from './TabMatchRules/Time';
import Shift from './TabMatchRules/Shift';
import MatchInSpeacial from './TabMatchRules/MatchInSpeacial';
import { mixGetV1 } from '../../services/mixin'
import dayjs from 'dayjs'
import { mixPostV1 } from '../../services/mixin'
// import './Styles/MatchRules.scss'
import './style.scss'

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const SettingMatchRules:React.FC = () => {
  const [isSubmit, setIsSubmit] = useState<any>(0)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActiveDay, setActiveDay] = useState<boolean>(false);

  const handleToggle = () => {
    setActiveDay(prevState => !prevState);
  };

  const [matchRules, setMatchRules] = useState({
    default_match: [],
    match_type: 1,
    hour_in_shift: 1,
    hour_in_time: 1,
    hour_in_hour: 1,
    listMatchTime: []
  } as any)
  const [matchSpecial, setMatchSpecial] = useState([] as any)
  let contx = 0
  const getMrules = async () => {
    if (contx > 0) { return }
    contx = 1;
    const res = await mixGetV1('/api/webAdmin/gSettingmatchRules.php')
    if (res.status === 1) {
      const temp = [] as any
      if (res.MatchRules && res.MatchRules.listMatchTime) {
        res.MatchRules.listMatchTime.map((item: any) => {
          temp.push({
            ...item,
            time: [dayjs(item.from_time, 'HH:mm'), dayjs(item.to_time, 'HH:mm')],
            from_time: dayjs(item.from_time, 'HH:mm'),
            to_time: dayjs(item.to_time, 'HH:mm'),
          })
          return true
        })
        res.MatchRules.listMatchTime = [...temp]
      }
      const defaultMatch = res.MatchDefault.length > 0 ? res.MatchDefault.map((item: any) => item.match_amount.toString()) : []
      setMatchRules({...matchRules, ...res.MatchRules, default_match: defaultMatch})

      const tempSpecial = [] as any
      res.MatchSpecial.map((item: any) => {
        tempSpecial.push({
          ...item,
          special_day: dayjs(`${item.special_day}-${dayjs().format('YYYY')}`, 'MM-DD'),
          is_active: item.is_active === 1
        })
        return true
      })
      setMatchSpecial(tempSpecial)
      contx = 0


      if (defaultMatchForm.current) {
        defaultMatchForm.current?.setFieldValue('defaultMatch', defaultMatch)
      }
    }
  }
  useEffect(() => {
    getMrules()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [isTimeFormError, setIsTimeFormError] = useState(false)
  const timeFormError = (isError: boolean) => {
    setIsTimeFormError(isError)
  }
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <div className="text-light font-bold t-fz-16" style={{ margin: '16px 0 -8px 0' }}>Rules</div>
          <div className="text-or">Match</div>
          <div className="title-collapse">
            <div className="icon-svg"><I24Support size="18" variant="Bulk" /></div>
            <div className="text t-fz-16">Match in shift</div>
          </div>
        </>
      ),
      children: <Shift
        hourinshift={matchRules.hour_in_shift}
        changeHourinshift={(e: any) => {
          setMatchRules({...matchRules, hour_in_shift: e})
        }}
        disabled={settingRole && settingRole.is_edit === 0}
      />,
    },
    {
      key: '2',
      label: (
        <>
          <div className="text-or">OR</div>
          <div className="title-collapse">
            <div className="icon-svg"><CalendarAdd size="18" variant="Bulk" /></div>
            <div className="text t-fz-16">Match in time</div>
          </div>
        </>
      ),
      children: <Time
        formFinish={(data: any) => listMatchTimeChange(data)}
        isSubmit={isSubmit}
        matchRules={matchRules}
        changeHourintime={(e: any) => {
          setMatchRules({...matchRules, hour_in_time: e})
        }}
        formError={timeFormError}
        createDisabled={settingRole && settingRole.is_create === 0}
        editDisabled={settingRole && settingRole.is_edit === 0}
        deleteDisabled={settingRole && settingRole.is_delete === 0}
      />,
    },
    {
      key: '3',
      label: (
        <>
          <div className="text-or">OR</div>
          <div className="title-collapse">
            <div className="icon-svg"><CalendarAdd size="18" variant="Bulk" /></div>
            <div className="text t-fz-16">Match in hour</div>
          </div>
        </>
      ),
      children: <Hour
        hourinhour={matchRules.hour_in_hour}
        changeHourinhour={(e: any) => {
          setMatchRules({...matchRules, hour_in_hour: e})
        }}
        editDisabled={settingRole && settingRole.is_edit === 0}
      />,
    },
  ]

  const changeType = (e: any) => {
    const type = Number(e[0])
    setMatchRules({...matchRules, match_type: type})
  }

  const [checkForm1, setCheckForm1] = useState<any>(false)
  const [checkForm2, setCheckForm2] = useState<any>(false)
  const [parammatchSpecial, setParammatchSpecial] = useState<any>('[]')
  const [paramlistMatchTime, setParamlistMatchTime] = useState<any>('[]')
  const [loading, setLoading] = useState(false)

  const [isSpecialFormError, setIsSpecialFormError] = useState(false)
  const specialFormError = (isError: boolean) => {
    setIsSpecialFormError(isError)
  }

  const matchInSpeacialChange = (data: any) => {
    setParammatchSpecial(JSON.stringify(data))
    if (isSubmit > 0) {
      setCheckForm1(true)
      setIsSpecialFormError(false)
    }
  }
  const listMatchTimeChange = (data: any) => {
    setParamlistMatchTime(JSON.stringify(data))
    if (isSubmit > 0) {
      setCheckForm2(true)
      setIsTimeFormError(false)
    }
  }
  const isUpdate = () => {
    if (defaultMatchForm.current) {
      defaultMatchForm.current.submit()
    }
    setTimeout(() => {
      setCheckForm1(false)
      setCheckForm2(false)
      setIsSubmit(isSubmit + 1)
    }, 100)
  }

  const defaultMatchForm:any = useRef(null)
  const [isDefaultMatchFormError, setIsDefaultMatchFormError] = useState(false)

  useEffect(() => {
    checkValidBeforeSubmit()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkForm1, checkForm2])

  const checkValidBeforeSubmit = () => {
    if (isSpecialFormError || isTimeFormError || isDefaultMatchFormError) return

    const defaultMatch = matchRules.default_match.map((item: any) => Number(item))
    const matchType = matchRules.match_type
    let formParams:any = {
      match_type: matchType,
      default_match: JSON.stringify(defaultMatch),
      matchSpecial: parammatchSpecial,
    }

    if (matchType === 1) {
      formParams = {
        ...formParams,
        hour_in_shift: matchRules.hour_in_shift
      }
    }
    if (matchType === 2) {
      formParams = {
        ...formParams,
        hour_in_time: matchRules.hour_in_time,
        listMatchTime: paramlistMatchTime,
      }
    }
    if (matchType === 3) {
      formParams = {
        ...formParams,
        hour_in_hour: matchRules.hour_in_hour
      }
    }

    if (matchType === 2 && checkForm2) {
      changeData(formParams)
    } else if (checkForm1) {
      changeData(formParams)
    }
  }

  let waitx = false
  const changeData = async (params: any) => {
    // console.log(params)
    setLoading(true)
    if (waitx === true) {
      return
    }
    waitx = true
    const res = await mixPostV1('/api/webAdmin/updateMatchRule.php', params)
    if (res.status === 1) {
      setLoading(false)
      openNotification()
      getMrules()
      waitx = false
      setIsSubmit(0)
    } else {
      setLoading(false)
      openNotification('error', res.message)
      waitx = false
      setIsSubmit(0)
    }
  }
   // NOTIFICATION
   const [api, contextHolder] = notification.useNotification();
   const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'MatchRules updated!' ) => {
     api[type]({
       message: message,
       placement: 'bottomRight',
       className: 'single-message'
     });
   };
  return (
    <SettingLayout current="match-rules">
      { contextHolder }
      <div className="setting-content setting-content--update setting-shift tab-setting">
        <div className="mw-687 shift-content tab-match-rules">
          <Flex
            vertical
            gap={8}
          >
            <div className="text-light font-bold t-fz-16">Default match</div>
            <Form
              ref={defaultMatchForm}
              onFinishFailed={() => setIsDefaultMatchFormError(true)}
              onFinish={() => setIsDefaultMatchFormError(false)}
            >
              <Form.Item
                name={'defaultMatch'}
                rules={[
                  { required: true, message: 'Please filled this field!' },
                ]}
              >
                <Select
                  // value={matchRules.default_match}
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Default match"
                  popupMatchSelectWidth={false}
                  suffixIcon={<TicketStar variant='Bulk' fontSize={18} />}
                  className='rs-select match-tag'
                  popupClassName='rs-select-dropdown'
                  // onInputKeyDown={(e) => { if(e.keyCode < 48 || e.keyCode > 57) e.preventDefault()}}
                  onInputKeyDown={(e: any) => { if(isNaN(e.key) && e.key !== 'Backspace') e.preventDefault()}}
                  onChange={(value) => setMatchRules({...matchRules, default_match: value})}
                  maxCount={3}
                  disabled={settingRole && settingRole.is_edit === 0}
                />
              </Form.Item>
            </Form>
            <Collapse accordion items={items} activeKey={[matchRules.match_type]} onChange={(e)=> changeType(e)} />

            <div className="line-and">
              <div className="text">AND</div>
            </div>
            <div className={`match-in-speacial-day-block active`}>
              <div className="title-match-in-speacial-day" onClick={handleToggle}>
                <div className="icon-svg"><CalendarAdd size="18" variant="Bulk" /></div>
                <div className="text t-fz-16">Match in speacial day</div>
                {/* <div className="icon-acitve">{checked}</div> */}
              </div>
              <div className={`content-match-in-speacial-day active`}>
                <MatchInSpeacial
                  matchSpecial={matchSpecial}
                  isSubmit={isSubmit}
                  formFinish={(data: any) => { matchInSpeacialChange(data) }}
                  formError={specialFormError}
                  createDisabled={settingRole && settingRole.is_create === 0}
                  editDisabled={settingRole && settingRole.is_edit === 0}
                  deleteDisabled={settingRole && settingRole.is_delete === 0}
                />
              </div>
            </div>
          </Flex>
        </div>

        <Button
          className='rs-button button-update'
          type='primary'
          loading={loading}
          disabled={settingRole && settingRole.is_edit === 0}
          onClick={() => isUpdate()}
        >Update</Button>
      </div>
    </SettingLayout>
  )
}

export default SettingMatchRules
