import React, { useEffect, useState, } from 'react';
import { Link, useLocation } from "react-router-dom";
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import './Menu.scss'
import { CardTick, Category, Crown1, Gameboy, Gift, Graph, ReceiptDisscount, Setting2, Setting3, ShopAdd, UserSquare } from 'iconsax-react';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

// storeTypeId === 4 (Match only), Menu will show: Report( dashboard),  Member, Voucher, Store game, Setting, Request Store
const storeTypeId = localStorage.getItem('storeTypeId')

// role_id: 1:admin, 2:owner, 3:sales, 4:user
const user = JSON.parse(localStorage.getItem('user') || '{}')
const roleId: any = user.role_id

/*
  Check permission for "User" - role_id === 4
  1: Report
  2: Machine
  3: Member
  4: Voucher
  5: Staff
  6: Store Game
  7: Setting
*/

const permission = user.permission
let showReport
let showMachine
let showMember
let showVoucher
let showStaff
let showStoreGame
let showSetting

const isShowFunc = (functionId: number) => {
  const permissionReport = permission.find((item: any) => item.function_id === functionId)
  if (
    permissionReport.is_view === 1 ||
    permissionReport.is_create === 1 ||
    permissionReport.is_edit === 1 ||
    permissionReport.is_delete === 1
  ) {
    return true
  } else {
    return false
  }
}

if (permission) {
  showReport = isShowFunc(1)
  showMachine = isShowFunc(2)
  showMember = isShowFunc(3)
  showVoucher = isShowFunc(4)
  showStaff = isShowFunc(5)
  showStoreGame = isShowFunc(6)
  showSetting = isShowFunc(7)
}

const storeManagementArray = [
  // REPORT
  // !permission || showReport ? getItem('Report', 'report', <Graph variant="Bulk" />, [
  //   getItem(
  //     <Link to={'/report/dashboard'} rel="noopener noreferrer">
  //       Dashboard
  //     </Link>,
  //     '/report/dashboard',
  //   ),
  //   getItem(
  //     <Link to={'/report/total-income'} rel="noopener noreferrer">
  //       Total income
  //     </Link>,
  //     '/report/total-income',
  //   ),
  //   getItem(
  //     <Link to={'/report/machine-income'} rel="noopener noreferrer">
  //       Machine income
  //     </Link>,
  //     '/report/machine-income',
  //   ),
  //   getItem(
  //     <Link to={'/report/staff'} rel="noopener noreferrer">
  //       Staff
  //     </Link>,
  //     '/report/staff',
  //   ),
  // ]) : null,
  !permission || showReport
    ?
      Number(storeTypeId) === 4
        ?
          getItem('Report', 'report', <Graph variant="Bulk" />, [
            getItem(
              <Link to={'/report/dashboard'} rel="noopener noreferrer">
                Dashboard
              </Link>,
              '/report/dashboard',
            ),
          ])
        :
          getItem('Report', 'report', <Graph variant="Bulk" />, [
            getItem(
              <Link to={'/report/dashboard'} rel="noopener noreferrer">
                Dashboard
              </Link>,
              '/report/dashboard',
            ),
            getItem(
              <Link to={'/report/total-income'} rel="noopener noreferrer">
                Total income
              </Link>,
              '/report/total-income',
            ),
            getItem(
              <Link to={'/report/machine-income'} rel="noopener noreferrer">
                Machine income
              </Link>,
              '/report/machine-income',
            ),
            getItem(
              <Link to={'/report/staff'} rel="noopener noreferrer">
                Staff
              </Link>,
              '/report/staff',
            ),
          ])
    : null,

  // MACHINE
  (!permission || showMachine) && Number(storeTypeId) !== 4 ? getItem(
    <Link to={'/machine'} rel="noopener noreferrer">
      Machine
    </Link>,
    '/machine',
    <Gameboy variant="Bulk" />
  ) : null,

  // STAFF
  (!permission || showStaff) && Number(storeTypeId) !== 4 ? getItem('Staff', 'staff', <UserSquare variant="Bulk" />, [
    getItem(
      <Link to={'/staff/manage'} rel="noopener noreferrer">
        Manage
      </Link>,
      '/staff/manage',
    ),
    getItem(
      <Link to={'/staff/cash-out'} rel="noopener noreferrer">
        Match/Cash-out
      </Link>,
      '/staff/cash-out',
    ),
  ]) : null,

  // MEMBER
  !permission || showMember ? getItem(
    <Link to={'/member'} rel="noopener noreferrer">
      Member
    </Link>,
    '/member',
    <Crown1 variant="Bulk" />
  ) : null,

  // VOUCHER
  !permission || showVoucher ? getItem(
    <Link to={'/voucher'} rel="noopener noreferrer">
      Voucher
    </Link>,
    '/voucher',
    <ReceiptDisscount variant="Bulk" />
  ) : null,

  // STORE GAME
  !permission || showStoreGame ? getItem('Store Game', 'store-game', <Gift variant="Bulk" />, [
    getItem(
      <Link to={'/store-game/lucky-game'} rel="noopener noreferrer">
        Lucky Game
      </Link>,
      '/store-game/lucky-game',
    ),
    getItem(
      <Link to={'/store-game/random-game'} rel="noopener noreferrer">
        Random Game
      </Link>,
      '/store-game/random-game',
    ),
  ]) : null,

  // SETTING
  !permission || showSetting ? getItem(
    <Link to={'/setting'} rel="noopener noreferrer">
      Setting
    </Link>,
    '/setting',
    <Setting3 variant="Bulk" />,
  ) : null,
]

// Role "Admin" và "Owner"
if (roleId === 1 || roleId === 2) {
  storeManagementArray.push(
    getItem(
      <Link to={'/request-store'} rel="noopener noreferrer">
        Request Store
      </Link>,
      '/request-store',
      <ShopAdd variant="Bulk" />,
    ),
  )
}

// Tất cả Role, ngoại trừ "Seller"
const storeManagement = (roleId !== 3)
  ? getItem(
      'Store Management', 'store-tool', null,
      storeManagementArray,
      'group'
    )
  : null

const adminManagement = roleId === 1
  ? getItem(
      'Admin Management', 'admin-tool', null,
      [
        // STORE LIST
        getItem('Store list', 'store-list', <Category variant="Bulk" />, [
          getItem(
            <Link to={'/store-list'} rel="noopener noreferrer">
              Live store
            </Link>,
            '/store-list',
          ),
          getItem(
            <Link to={'/store-list/owner-request'} rel="noopener noreferrer">
              Owner request
            </Link>,
            '/store-list/owner-request',
          ),
        ]),

        // ACCOUNT LIST
        getItem('Account list', 'account-list', <UserSquare variant="Bulk" />, [
          getItem(
            <Link to={'/account-list/owner'} rel="noopener noreferrer">
              Owner
            </Link>,
            '/account-list/owner',
          ),
          getItem(
            <Link to={'/account-list/seller'} rel="noopener noreferrer">
              Seller
            </Link>,
            '/account-list/seller',
          ),
        ]),

        // SALES REPORT
        getItem(
          <Link to={'/sales-report'} rel="noopener noreferrer">
            Sales Report
          </Link>,
          '/sales-report',
          <Graph variant="Bulk" />,
        ),

        // LIST PAYMENT
        getItem(
          <Link to={'/list-payment'} rel="noopener noreferrer">
            List payment
          </Link>,
          '/list-payment',
          <CardTick variant="Bulk" />,
        ),

        // SETTING TYPE
        getItem(
          <Link to={'/setting-type'} rel="noopener noreferrer">
            Setting type
          </Link>,
          '/setting-type',
          <Setting2 variant="Bulk" />,
        ),

        // LIST DEVICE
        getItem(
          <Link to={'/list-device'} rel="noopener noreferrer">
            List device
          </Link>,
          '/list-device',
          <Gameboy variant="Bulk" />,
        ),

        // ACCOUNT SETTING
        getItem(
          <Link to={'/account-setting'} rel="noopener noreferrer">
            Account setting
          </Link>,
          '/account-setting',
          <Setting2 variant="Bulk" />,
        ),
      ],
      'group'
    )
  : roleId === 3
    ? getItem(
        'Admin Management', 'admin-tool', null,
        [
          // STORE LIST
          getItem('Store list', 'store-list', <Category variant="Bulk" />, [
            getItem(
              <Link to={'/store-list'} rel="noopener noreferrer">
                Live store
              </Link>,
              '/store-list',
            ),
          ]),

          // ACCOUNT LIST
          getItem('Account list', 'account-list', <UserSquare variant="Bulk" />, [
            getItem(
              <Link to={'/account-list/owner'} rel="noopener noreferrer">
                Owner
              </Link>,
              '/account-list/owner',
            ),
          ]),

          // SALES REPORT
          getItem(
            <Link to={'/sales-report'} rel="noopener noreferrer">
              Sales Report
            </Link>,
            '/sales-report',
            <Graph variant="Bulk" />,
          ),

          // LIST PAYMENT
          getItem(
            <Link to={'/list-payment'} rel="noopener noreferrer">
              List payment
            </Link>,
            '/list-payment',
            <CardTick variant="Bulk" />,
          ),

          // ACCOUNT SETTING
          getItem(
            <Link to={'/account-setting'} rel="noopener noreferrer">
              Account setting
            </Link>,
            '/account-setting',
            <Setting2 variant="Bulk" />,
          ),
        ],
        'group'
      )
    : null

const items: MenuProps['items'] = [
  // getItem('Store Management', 'store-tool', null, [
  //   // REPORT
  //   getItem('Report', 'report', <Graph variant="Bulk" />, [
  //     getItem(
  //       <Link to={'/report/dashboard'} rel="noopener noreferrer">
  //         Dashboard
  //       </Link>,
  //       '/report/dashboard',
  //     ),
  //     getItem(
  //       <Link to={'/report/total-income'} rel="noopener noreferrer">
  //         Total income
  //       </Link>,
  //       '/report/total-income',
  //     ),
  //     getItem(
  //       <Link to={'/report/machine-income'} rel="noopener noreferrer">
  //         Machine income
  //       </Link>,
  //       '/report/machine-income',
  //     ),
  //     getItem(
  //       <Link to={'/report/staff'} rel="noopener noreferrer">
  //         Staff
  //       </Link>,
  //       '/report/staff',
  //     ),
  //   ]),

  //   // MACHINE
  //   getItem(
  //     <Link to={'/machine'} rel="noopener noreferrer">
  //       Machine
  //     </Link>,
  //     '/machine',
  //     <Gameboy variant="Bulk" />
  //   ),

  //   // STAFF
  //   getItem('Staff', 'staff', <UserSquare variant="Bulk" />, [
  //     getItem(
  //       <Link to={'/staff/manage'} rel="noopener noreferrer">
  //         Manage
  //       </Link>,
  //       '/staff/manage',
  //     ),
  //     getItem(
  //       <Link to={'/staff/cash-out'} rel="noopener noreferrer">
  //         Match/Cash-out
  //       </Link>,
  //       '/staff/cash-out',
  //     ),
  //   ]),

  //   // MEMBER
  //   getItem(
  //     <Link to={'/member'} rel="noopener noreferrer">
  //       Member
  //     </Link>,
  //     '/member',
  //     <Crown1 variant="Bulk" />
  //   ),

  //   // VOUCHER
  //   getItem(
  //     <Link to={'/voucher'} rel="noopener noreferrer">
  //       Voucher
  //     </Link>,
  //     '/voucher',
  //     <ReceiptDisscount variant="Bulk" />
  //   ),

  //   // STORE GAME
  //   getItem('Store Game', 'store-game', <Gift variant="Bulk" />, [
  //     getItem(
  //       <Link to={'/store-game/lucky-game'} rel="noopener noreferrer">
  //         Lucky Game
  //       </Link>,
  //       '/store-game/lucky-game',
  //     ),
  //     getItem(
  //       <Link to={'/store-game/random-game'} rel="noopener noreferrer">
  //         Random Game
  //       </Link>,
  //       '/store-game/random-game',
  //     ),
  //   ]),

  //   getItem(
  //     <Link to={'/setting'} rel="noopener noreferrer">
  //       Setting
  //     </Link>,
  //     '/setting',
  //     <Setting3 variant="Bulk" />,
  //   ),

  //   getItem(
  //     <Link to={'/request-store'} rel="noopener noreferrer">
  //       Request Store
  //     </Link>,
  //     '/request-store',
  //     <ShopAdd variant="Bulk" />,
  //   ),
  // ], 'group'),

  // ADMIN TOOL

  // getItem('Admin Management', 'admin-tool', null, [
  //   // STORE LIST
  //   getItem('Store list', 'store-list', <Category variant="Bulk" />, [
  //     getItem(
  //       <Link to={'/store-list'} rel="noopener noreferrer">
  //         Live store
  //       </Link>,
  //       '/store-list',
  //     ),
  //     getItem(
  //       <Link to={'/store-list/owner-request'} rel="noopener noreferrer">
  //         Owner request
  //       </Link>,
  //       '/store-list/owner-request',
  //     ),
  //   ]),

  //   // ACCOUNT LIST
  //   getItem('Account list', 'account-list', <UserSquare variant="Bulk" />, [
  //     getItem(
  //       <Link to={'/account-list/owner'} rel="noopener noreferrer">
  //         Owner
  //       </Link>,
  //       '/account-list/owner',
  //     ),
  //     getItem(
  //       <Link to={'/account-list/seller'} rel="noopener noreferrer">
  //         Seller
  //       </Link>,
  //       '/account-list/seller',
  //     ),
  //   ]),

  //   // SALES REPORT
  //   getItem(
  //     <Link to={'/sales-report'} rel="noopener noreferrer">
  //       Sales Report
  //     </Link>,
  //     '/sales-report',
  //     <Graph variant="Bulk" />,
  //   ),

  //   // LIST PAYMENT
  //   getItem(
  //     <Link to={'/list-payment'} rel="noopener noreferrer">
  //       List payment
  //     </Link>,
  //     '/list-payment',
  //     <CardTick variant="Bulk" />,
  //   ),

  //   // ACCOUNT SETTING
  //   getItem(
  //     <Link to={'/account-setting'} rel="noopener noreferrer">
  //       Account setting
  //     </Link>,
  //     '/account-setting',
  //     <Setting2 variant="Bulk" />,
  //   ),
  // ], 'group')

  storeManagement,
  adminManagement
];

const WidgetMenu: React.FC = () => {
  const pathConvert = (path: string) => {
    const pathArr = path.split('/')
    let result = ''

    if (pathArr.length > 3) {
      result = [pathArr[0], pathArr[1], pathArr[2]].join('/')
    } else {
      result = path
    }
    return result
  }

  let location = useLocation()
  const [current, setCurrent] = useState(
    location.pathname === '/' || location.pathname === ''
      ? '/'
      : pathConvert(location.pathname)
  )
  const currentParentPath = location.pathname.split('/')[1] || ''

  const parents = ['staff', 'store-game', 'account-list', 'store-list', 'setting', 'report',]
  const [currentParent, setCurrentParent] = useState(
    parents.find(item => item === currentParentPath) || ''
  )

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname)
      }
      const parents = ['staff', 'store-game', 'account-list', 'store-list', 'setting', 'report',]
      const parentName = parents.find(item => item === currentParentPath) || ''
      setCurrentParent(parentName)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, current])

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
  };

  // const [collapsed, setCollapsed] = useState(false);
  // const toggleCollapsed = () => {
  //   const appElem = document.getElementsByClassName('app')[0]
  //   if (!appElem) return false
  //   appElem.classList.toggle('menu-collapsed')
  //   setCollapsed(!collapsed);
  // };

  return (
    <div className='app-menu-vertical'>
      <Menu
        onClick={onClick}
        defaultSelectedKeys={[current]}
        defaultOpenKeys={[currentParent]}
        mode="inline"
        items={items}
        // inlineCollapsed={collapsed}
        className='rs-scrollbar'
        expandIcon={false}
      />
      {/* <div
        className='menu-trigger'
        onClick={toggleCollapsed}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div> */}
    </div>
  );
};

export default WidgetMenu;
