import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Badge, Button, Flex, Input, PaginationProps, Table, TableColumnsType, Tabs, TabsProps, notification } from 'antd'
import { CloseCircle, DocumentDownload, Home2, SearchZoomIn1, } from 'iconsax-react'
import { ArrowLeftOutlined, ArrowRightOutlined, } from '@ant-design/icons'
// import { storeType } from '../../services/mixOption'
import LockModal from '../../components/Modal/LockModal'
import { mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'

const Stamp = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_3063_17608)"> <path d="M26.1 20.7999H24.9C24.4 20.7999 24 20.3999 24 19.8999C24 19.5999 24.1 19.1999 24.3 18.9999C25.3 17.6999 25.7 15.9999 25.4 14.3999C25 12.2999 23.3 10.5999 21.2 10.1999C19.5 9.79991 17.8 10.1999 16.5 11.1999C15.2 12.2999 14.5 13.7999 14.5 15.4999C14.5 16.7999 14.9 17.9999 15.7 18.9999C15.9 19.1999 16 19.5999 16 19.8999C16 20.3999 15.6 20.7999 15.1 20.7999H13.9C11.9 20.7999 10.3 22.3999 10.3 24.3999V26.3999C10.4 26.6999 10.7 26.9999 11 26.9999H29C29.4 26.9999 29.7 26.6999 29.7 26.2999V24.2999C29.6 22.3999 28 20.7999 26.1 20.7999ZM28.3 25.6999H11.7V24.2999C11.7 23.0999 12.7 22.0999 13.9 22.0999H15.1C16.3 22.0999 17.3 21.0999 17.3 19.8999C17.3 19.2999 17.1 18.5999 16.7 18.1999C16.1 17.3999 15.8 16.4999 15.8 15.4999C15.8 14.1999 16.4 12.9999 17.4 12.1999C18.4 11.3999 19.7 11.0999 21 11.3999C22.6 11.6999 23.9 12.9999 24.2 14.5999C24.5 15.8999 24.2 17.0999 23.4 18.0999C23 18.5999 22.8 19.1999 22.8 19.7999C22.8 20.9999 23.8 21.9999 25 21.9999H26.2C27.4 21.9999 28.4 22.9999 28.4 24.1999V25.6999H28.3ZM29 28.6999H11C10.6 28.6999 10.3 28.9999 10.3 29.3999C10.3 29.7999 10.7 29.9999 11 29.9999H29C29.4 29.9999 29.7 29.6999 29.7 29.2999C29.7 28.8999 29.3 28.6999 29 28.6999Z" fill="#55C790"/> </g> <defs> <clipPath id="clip0_3063_17608"> <rect width="20" height="20" fill="white" transform="translate(10 10)"/> </clipPath> </defs> </svg>

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const StoreList: React.FC = () => {
  const [showDeleteStorePopup, setShowDeleteStorePopup] = useState(false)
  const [lockModalType, setLockModalType] = useState('lock')
  const [selectedTab, setSelectedTab] = useState('waiting')
  const [selectedItem, setSelectedItem] = useState({} as any)
  const columns: TableColumnsType = [
    {
      title: '',
      key: 'actions',
      fixed: 'left',
      className: 'actions-sticky actions-sticky--left',
      render: (record) => (
        <Flex align='center' gap={4}>
          <Button
            icon={<CloseCircle variant='Outline' size={20} />}
            className="rs-button delete"
            onClick={()=> {
              setSelectedItem(record)
              setLockModalType('lock')
              setShowDeleteStorePopup(true)
            }}
          />
          <Button
            icon={Stamp}
            className="rs-button edit"
            onClick={()=> {
              setSelectedItem(record)
              setLockModalType('un-lock')
              setShowDeleteStorePopup(true)
            }}
          />
        </Flex>
      ),
      hidden: selectedTab !== 'waiting'
    },
    {
      title: 'Store',
      dataIndex: 'store',
      key: 'store',
    },
    {
      title: 'Store ID',
      dataIndex: 'store_id',
      key: 'store_id',
    },
    {
      title: 'Store name',
      dataIndex: 'store_name',
      key: 'store_name',
    },
    {
      title: 'Type',
      dataIndex: 'type_id',
      key: 'type_id',
      render: (type_id, record) => (
        // <Badge status={type_id === 1 ? 'warning' : 'default'} text={ record.type_name } className='rs-badge background' />
        <Badge status={type_id === 1 ? 'warning' : 'default'} text={ record.type_name } className='rs-badge background' />
      ),
    },
    {
      title: 'Owner name',
      dataIndex: 'owner_name',
      key: 'owner_name',
    },
    {
      title: 'Owner Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Owner email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Request at',
      dataIndex: 'create_at',
      key: 'create_at',
    },
    {
      title: 'Seller',
      dataIndex: 'seller_name',
      key: 'seller_name',
      render: (text, record) => (
        <Flex align='center' gap={8}>
          <Flex vertical>
            <span>{ text }</span>
            <span className="title-12 neutral-gray-500">Seller ID { record.seller_id }</span>
          </Flex>
        </Flex>
      )
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ]

  const [items, setItems] = useState({
    list: [],
    total: 0,
    totalAccepted: 0,
    totalWaiting: 0,
    totalRejected: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    searchKey?: string,
  }>({
    limit: 10,
    page_number: 1,
    searchKey: ''
  })

  // TABS
  const tabs: TabsProps['items'] = [
    {
      key: 'waiting',
      label: (
        <div className="title-tab-setting">
          <div className="text t-fz-16">Waiting ({ items.totalWaiting })</div>
        </div>
      ),
    },
    {
      key: 'accepted',
      label: (
        <div className="title-tab-setting">
          <div className="text t-fz-16">Accepted ({ items.totalAccepted })</div>
        </div>
      ),
    },
    {
      key: 'rejected',
      label: (
        <div className="title-tab-setting">
          <div className="text t-fz-16">Rejected ({ items.totalRejected })</div>
        </div>
      ),
    },
  ]
  const onTabChange = (key: string) => {
    setSelectedTab(key)
  }
  // END TABS

  // GET ACCEPTED STORES
  const getAcceptedStores = async () => {
    setTableLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/gListStoreOwnerAccepted.php', params)
      if (res.status === 1) {
        setItems({
          ...res,
          list: res.data,
          total: res.totalAccepted,
        })
        setTableLoading(false)
      }
    } catch (error) {
      setTableLoading(false)
    }
  }
  // END GET ACCEPTED STORES

  // GET REJECTED STORES
  const getRejectedStores = async () => {
    setTableLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/gListStoreOwnerRejected.php', params)
      if (res.status === 1) {
        setItems({
          ...res,
          list: res.data,
          total: res.totalRejected,
        })
        setTableLoading(false)
      }
    } catch (error) {
      setTableLoading(false)
    }
  }
  // END GET REJECTED STORES

  // GET WAITING STORES
  const getWaitingStores = async () => {
    setTableLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/gListStoreOwnerWaiting.php', params)
      if (res.status === 1) {
        setItems({
          ...res,
          list: res.data,
          total: res.totalWaiting,
        })
      }
      setTableLoading(false)
    } catch (error) {

    } setTableLoading(false)
  }
  // END GET WAITING STORES

  useEffect(() => {
    switch (selectedTab) {
      case 'accepted':
        getAcceptedStores()
        break

      case 'rejected':
        getRejectedStores()
        break

      case 'waiting':
        getWaitingStores()
        break

      default:
        break
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (query: string) => {
    setParams({
      ...params,
      page_number: 1,
      searchKey: query,
    })
  }
  const onEnter = (e: any)  => {
    onSearch(e.target.value)
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e.target.value)
    }, 250)
    setSearchTimeout(timeout)
  }
  // END SEARCH

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }
    let res: any

    try {
      switch (selectedTab) {
        case 'accepted':
          res = await mixPostV1('/api/webAdmin/gListStoreOwnerAccepted.php', customParams)
          break

        case 'rejected':
          res = await mixPostV1('/api/webAdmin/gListStoreOwnerRejected.php', customParams)
          break

        case 'waiting':
          res = await mixPostV1('/api/webAdmin/gListStoreOwnerWaiting.php', customParams)
          break

        default:
          break
      }

      if (res.status === 1) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Store': item.store,
        'Store ID': item.store_id,
        'Store name': item.store_name,
        // 'Type': item.type_name,
        'Owner name': item.owner_name,
        'Owner phone': item.phone_number,
        'Owner email': item.email,
        'Request at': item.create_at,
        'Seller': item.seller_name,
        'Address': item.address,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Owner request list"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Staff created!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <Layout contentClassName="store-list-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Store List', },
            { title: 'Owner request', },
          ]}
          title='Owner request'
          description=''
        />

        <Tabs
          activeKey={selectedTab}
          items={tabs}
          className='rs-tabs rs-tabs--no-tab-content'
          onChange={onTabChange}
        />

        <div className="table-filter-block">
          {/* <Select
            defaultValue=""
            options={[
              { value: 'jak-shol', label: 'Jak Shol' },
            ]}
            suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
            popupMatchSelectWidth={false}
            className="rs-select mw-288"
            popupClassName='rs-select-dropdown'
            labelRender={(props: any) => {
              const { label } = props

              if (label) {
                return <Flex align='center' gap={8}><UserSquare size={20} variant="Bulk"/><span>{ label }</span></Flex>
              }
              return <Flex align='center' gap={8}><UserSquare size={20} variant="Bulk"/><span>Select Owner</span></Flex>
            }}
          /> */}

          <Input
            allowClear
            placeholder="Search by name, phone"
            prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
            className="rs-input table-filter-block__search"
            onPressEnter={(e) => onEnter(e)}
            onChange={(e) => onChange(e)}
          />

          <Button
            icon={<DocumentDownload size={20} variant="Bulk"/>}
            className="rs-button mis-auto"
            onClick={getItemsByExcel}
          >Export</Button>
        </div>

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table table-text-nowrap match-height"
          rowKey="store_id"
          loading={tableLoading}
          scroll={{
            x: true,
          }}
          pagination={{
            current: params.page_number,
            pageSize: params.limit,
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTitle: false,
            total: items.total,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />

        <LockModal
          isShow={showDeleteStorePopup}
          content={lockModalType === 'lock' ? 'Are you sure you want to reject this Store?' : 'Are you sure you want to accept this Store?'}
          message={lockModalType === 'lock' ? 'Store rejected!' : 'Store accepted!'}
          showReason={lockModalType === 'lock'}
          apiUrl={lockModalType === 'lock' ? '/api/webAdmin/updateStatusRejectStore.php' : '/api/webAdmin/updateStatusStore.php'}
          apiMethod={'PUT'}
          param={lockModalType === 'lock' ? {store_id: selectedItem.store_id} : {store_id: selectedItem.store_id, status: 1}}
          type={lockModalType}
          onModalClose={() => setShowDeleteStorePopup(false)}
          onModalSubmit={() => {
            getWaitingStores()
            setShowDeleteStorePopup(false)
          }}
        />
      </Layout>
    </>
  )
}

export default StoreList
