import React, { useState } from 'react'
import '../Login/style.scss'
import { useParams } from 'react-router-dom'
import { Input, Form, Button, Modal, Flex, notification } from 'antd'
import { Check, Eye, EyeSlash, LockCircle, Profile, ShieldTick } from 'iconsax-react'
import { mixPutV1 } from '../../services/mixin'
import { CheckCircleFilled } from '@ant-design/icons'
import type { FormInstance } from 'antd'

const closeIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z" fill="#6B7280" /> </svg>

const CreateSellerAccount: React.FC = () => {
  const [form] = Form.useForm()
  const [passwordLengthAccept, setPasswordLengthAccept] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const pathParams = useParams()

  // SUBMIT BUTTON
  interface SubmitButtonProps {
    form: FormInstance;
  }
  const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form }) => {
    const [submittable, setSubmittable] = React.useState<boolean>(false)

    // Watch all values
    const values = Form.useWatch([], form)

    React.useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false))
    }, [form, values])

    return (
      <Button
        className={`btn t-btn-primary h-48 btn-new-pw `}
        type="primary"
        htmlType="submit"
        disabled={!submittable}
      >
        <div className="icon-svg">
          <Check variant="Bulk" />
        </div>
        <div className="text">
          Confirm new password
        </div>
      </Button>
    )
  }
  // END SUBMIT BUTTON

  // SUBMIT FORM
  const onFinish = async (values: any) => {
    const { code } = pathParams
    const params = {
      sellerCode: code,
      username: values.username,
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
    }

    try {
      const res = await mixPutV1('/api/webAdmin/setupAccountSeller.php', params)

      if (res.status === 1) {
        setResponseMessage(res.message)
        setShowModal(true)
        form.resetFields()
      } else {
        openNotification('error', res.message)
      }
    } catch (error) {

    }
  }
  // END SUBMIT FORM

  const onValuesChange = (changeValues: any, allValues: any) => {
    const { password, confirmPassword } = allValues

    if (password) {
      if (password.length >= 8 && password.length <= 15) {
        setPasswordLengthAccept(true)
      } else {
        setPasswordLengthAccept(false)
      }
    } else {
      setPasswordLengthAccept(false)
    }

    if (password === confirmPassword) {
      setPasswordMatch(true)
    } else {
      setPasswordMatch(false)
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = '!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <div className='login-page'>
      { contextHolder }

      <div className="bg-img">
        <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
      </div>

      <div className="content-block mw-464">
        <div style={{ marginBlockEnd: 35, lineHeight: 1 }}>
          <img src="/images/logo.png" alt="" />
        </div>

        <div className="title">Protect your account</div>
        <div className="sum t-fz-16">For your login security, please setup username, new password & pass code</div>

        <Form
          className="form-login-block form-new-pw"
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <div className="input-icon-block mb-24">
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'This field must be filled!' }]}
            >
              <div className={`h-44 b-r`}>
                <Input
                  placeholder="Username"
                  prefix={<Profile variant="Bulk" />}
                />
              </div>
            </Form.Item>
          </div>

          <div className="input-icon-block mb-24">
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'This field must be filled!' },
                { min: 8 },
                { max: 15 }
              ]}
            >
              <div className={`h-44 b-r`}>
                <Input.Password
                  // minLength={8}
                  // maxLength={15}
                  placeholder="Password"
                  prefix={<LockCircle variant="Bulk" />}
                  iconRender={(visible) => (visible ? <Eye variant="Bulk" /> : <EyeSlash variant="Bulk" />)}
                />
              </div>
            </Form.Item>
          </div>

          <div className="input-icon-block mb-24">
            <Form.Item
              dependencies={['password']}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The new password that you entered do not match!'));
                  },
                }),
              ]}
            >
              <div className={`h-44 b-r`}>
                <Input.Password
                  maxLength={15}
                  placeholder="Confirm password"
                  prefix={<LockCircle variant="Bulk" />}
                  iconRender={(visible) => (visible ? <Eye variant="Bulk" /> : <EyeSlash variant="Bulk" />)}
                  // onChange={handleConfirmPasswordChange}
                />
              </div>
            </Form.Item>
          </div>

          <div className="list-error mb-48">
            <div className={`one-error t-fz-16 ${passwordLengthAccept ? 'active' : ''}`}>
              <div className="icon-svg">
                <ShieldTick variant="Bulk" />
              </div>
              <div className="text">Password must be between 8 and 15 characters in length</div>
            </div>
            <div className={`one-error t-fz-16 ${passwordMatch ? 'active' : ''}`}>
              <div className="icon-svg">
                <ShieldTick variant="Bulk" />
              </div>
              <div className="text">New password & confirmation password must match exactly.</div>
            </div>
          </div>

          <Form.Item shouldUpdate>
            <div className={`mb-0 btn-block active`}>
              <SubmitButton form={form} />
            </div>
          </Form.Item>
        </Form>
      </div>

      <Modal
        centered
        open={showModal}
        closeIcon={closeIcon}
        footer={null}
        className='rs-modal w-360 modal-delete'
        rootClassName='rs-modal-root'
        onCancel={() => setShowModal(false)}
      >
        <Flex vertical align='center' gap={16} className='text-center'>
          <CheckCircleFilled style={{ fontSize: 64, color: '#0CAF60' }}/>

          <Flex vertical gap={4}>
            <div className="title-20 text-light">Successfully!</div>
            <div>{ responseMessage }</div>
          </Flex>
        </Flex>
      </Modal>
    </div>
  )
}

export default CreateSellerAccount
