import React, { useRef, useState } from 'react'
import './Game.scss'
import GameButton from './Button'
import DeleteModal from '../../../components/Modal/DeleteModal'

interface Props {
  userId?: string,
  userName?: string,
  isPlaying?: boolean,

  type?: string,
  disabledPlay?: boolean,
  players?: Array<any>,
  onPlay?: () => void
  onFinish?: () => void
  onCancel?: () => void
  onConfirm?: (winner: any) => void
}

const GameBar: React.FC<Props> = (props) => {
  const { type = 'lucky-game', disabledPlay, players, onPlay, onFinish, onConfirm } = props

  // const [gamePlaying, setGamePlaying] = useState(isPlaying)
  const [gamePlaying, setGamePlaying] = useState(false)
  const [winner, setWinner] = useState({} as any)
  const [showConFirm, setShowConfirm] = useState(false)
  const [showCancelPrizeModal, setShowCancelPrizeModal] = useState(false)
  const intervalIdRef = useRef(null as any)
  const audioRef = useRef(null as any)

  const onGamePlay = () => {
    if (players?.length === 0) return
    if (players?.length === 1) {
      setGamePlaying(true)
      setShowConfirm(true)
      setWinner(players[0])
      return
    }

    let time = 0
    setGamePlaying(true)

    if (audioRef.current) {
      audioRef.current.play()
    }
    let templeWinner = null as any
    const initWinner = randomWinner(players, templeWinner)
    templeWinner = initWinner
    setWinner(initWinner)

    intervalIdRef.current = setInterval(() => {
      time += 100
      const currentWinner = randomWinner(players, templeWinner)
      templeWinner = currentWinner
      setWinner(currentWinner)

      if (time >= 4000) {
        clearInterval(intervalIdRef.current)
        setShowConfirm(true)

        if (onFinish) {
          onFinish()
        }
      }
    }, 120)

    if (onPlay && !disabledPlay) {
      onPlay()
    }
  }

  const randomWinner = (players: any, currentWinner: any) => {
    let newArr = players
    if (players.length > 1 && currentWinner) {
      newArr = players.filter((item: any) => item.id !== currentWinner.id)
    }
    const randomIndex = Math.floor(Math.random() * newArr.length)
    return newArr[randomIndex]
  }

  const onGameCancel = () => {
    setGamePlaying(false)
    setShowConfirm(false)
    setShowCancelPrizeModal(false)
    setWinner({})
  }

  const onGameConfirm = () => {
    if (onConfirm) {
      setGamePlaying(false)
      setShowConfirm(false)
      onConfirm(winner)
    }
  }

  return (
    <>
      <div className="game-bar">
        <audio ref={audioRef}>
          <source src="/sound01.mp3" type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>

        <div className="game-bar__header" style={{ backgroundImage: 'url(/images/game-bar-header-bg.png)'}}>
          <img src="/images/favicon.svg" alt="CMD" className="game-bar__header-logo" />
        </div>

        <div className="game-bar__content">
          <div className="title">{ type === 'lucky-game' ? 'WINNER' : 'Result' }</div>

          <div className="announcement">
            <svg width="23" height="81" viewBox="0 0 23 81" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.5681 0.885864C10.7078 0.900532 7.00872 2.95103 4.27905 6.58934C1.54938 10.2277 0.0110043 15.1581 0 20.3034V61.4683C0.0110043 66.6137 1.54938 71.5441 4.27905 75.1824C7.00872 78.8207 10.7078 80.8712 14.5681 80.8859H21.3989C14.1329 79.8128 8.43188 71.5469 8.43188 61.5892V20.4243C8.44289 15.2789 9.98126 10.3485 12.7109 6.71019C15.4406 3.07187 19.1397 1.02138 23 1.00671" fill="url(#paint0_linear_562_10275)"/> <path d="M14.5681 0.885864C10.7078 0.900532 7.00872 2.95103 4.27905 6.58934C1.54938 10.2277 0.0110043 15.1581 0 20.3034V61.4683C0.0110043 66.6137 1.54938 71.5441 4.27905 75.1824C7.00872 78.8207 10.7078 80.8712 14.5681 80.8859H21.3989C14.1329 79.8128 8.43188 71.5469 8.43188 61.5892V20.4243C8.44289 15.2789 9.98126 10.3485 12.7109 6.71019C15.4406 3.07187 19.1397 1.02138 23 1.00671" fill="url(#paint1_linear_562_10275)" fillOpacity="0.8"/> <defs> <linearGradient id="paint0_linear_562_10275" x1="11.5" y1="-5.7808" x2="11.5" y2="114.219" gradientUnits="userSpaceOnUse"> <stop stopColor="#F9D361" stopOpacity="0.31"/> <stop offset="0.598958" stopColor="#F9D361"/> <stop offset="0.755208" stopColor="#B38600"/> <stop offset="0.854167" stopColor="#F9D361" stopOpacity="0"/> <stop offset="0.927083" stopColor="#F9D361" stopOpacity="0"/> </linearGradient> <linearGradient id="paint1_linear_562_10275" x1="11.5" y1="0.885865" x2="48.0876" y2="79.1217" gradientUnits="userSpaceOnUse"> <stop/> <stop offset="0.384993" stopOpacity="0"/> <stop offset="0.627157" stopOpacity="0.55"/> </linearGradient> </defs> </svg>

            <div className="announcement-text">
              {
                type === 'lucky-game' && (
                  gamePlaying
                    ?
                      <>
                        {/* <span className="name">{ userName }</span>
                        <span className="id">{ userId }</span> */}
                        <span className="name">{ winner.name }</span>
                        <span className="id">{ winner.id }</span>
                      </>
                    :
                      <>
                        <span className="name">Winner name</span>
                        <span className="id">winner app id</span>
                      </>
                )
              }
              {/* {
                type === 'random-game' && (
                  gamePlaying
                    ? <span className="name">{ userName }</span>
                    : <span className="name">Title</span>
                )
              } */}
            </div>

            <svg width="23" height="81" viewBox="0 0 23 81" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.43189 0.885864C12.2922 0.900532 15.9913 2.95103 18.721 6.58934C21.4506 10.2277 22.989 15.1581 23 20.3034V61.4683C22.989 66.6137 21.4506 71.5441 18.721 75.1824C15.9913 78.8207 12.2922 80.8712 8.43189 80.8859H1.60115C8.86707 79.8128 14.5681 71.5469 14.5681 61.5892V20.4243C14.5571 15.2789 13.0187 10.3485 10.2891 6.71019C7.5594 3.07187 3.86033 1.02138 5.72205e-06 1.00671" fill="url(#paint0_linear_562_10278)"/> <path d="M8.43189 0.885864C12.2922 0.900532 15.9913 2.95103 18.721 6.58934C21.4506 10.2277 22.989 15.1581 23 20.3034V61.4683C22.989 66.6137 21.4506 71.5441 18.721 75.1824C15.9913 78.8207 12.2922 80.8712 8.43189 80.8859H1.60115C8.86707 79.8128 14.5681 71.5469 14.5681 61.5892V20.4243C14.5571 15.2789 13.0187 10.3485 10.2891 6.71019C7.5594 3.07187 3.86033 1.02138 5.72205e-06 1.00671" fill="url(#paint1_linear_562_10278)" fillOpacity="0.8"/> <defs> <linearGradient id="paint0_linear_562_10278" x1="11.5" y1="-5.7808" x2="11.5" y2="114.219" gradientUnits="userSpaceOnUse"> <stop stopColor="#F9D361" stopOpacity="0.31"/> <stop offset="0.598958" stopColor="#F9D361"/> <stop offset="0.755208" stopColor="#B38600"/> <stop offset="0.854167" stopColor="#F9D361" stopOpacity="0"/> <stop offset="0.927083" stopColor="#F9D361" stopOpacity="0"/> </linearGradient> <linearGradient id="paint1_linear_562_10278" x1="11.5" y1="0.885865" x2="-25.0876" y2="79.1217" gradientUnits="userSpaceOnUse"> <stop/> <stop offset="0.384993" stopOpacity="0"/> <stop offset="0.627157" stopOpacity="0.55"/> </linearGradient> </defs> </svg>
          </div>

          <div className="separator">
            <img src="/images/game-bar-separator.svg" alt="" />
          </div>

          <div className="controls">
            {
              !gamePlaying &&
                <GameButton
                  title='Play'
                  disabled={disabledPlay}
                  onClick={onGamePlay}
                />
            }
            {
              showConFirm &&
                <>
                  <GameButton
                    title='Cancel'
                    onClick={() => setShowCancelPrizeModal(true)}
                  />
                  <GameButton
                    title='Confirm'
                    onClick={onGameConfirm}
                  />
                </>
            }
          </div>
        </div>

        <div className="game-bar__footer" style={{ backgroundImage: 'url(/images/game-bar-footer-bg.png)'}}></div>
      </div>

      {/* MODAL CANCEL PRIZE */}
      <DeleteModal
        isShow={showCancelPrizeModal}
        content={`Are you sure you want to \ncancel this prize?`}
        message='Prize is canceled!'
        yesText='Yes, cancel prize'
        onModalClose={() => setShowCancelPrizeModal(false)}
        onModalSubmit={onGameCancel}
      />
    </>
  )
}

export default GameBar
