import React from 'react'
import { Checkbox } from 'antd'
import './RolePermissionTable.scss'

interface Props {
  roleList?: any[],
  changeRole?: any
}

const RolePermissionTable:React.FC<Props> = (props) => {
  const { roleList, changeRole } = props
  const isFull = (item: any) => {
    return item.is_view === 1 && item.is_create === 1 && item.is_edit && item.is_delete
  }

  return (
    <div className="role-permission-table">
      <div className="role-permission-table__head">
        <div className="item">Function</div>
        <div className="item">Full permission</div>
        <div className="item">View</div>
        <div className="item">Create</div>
        <div className="item">Edit</div>
        <div className="item">Delete</div>
      </div>
      {
        roleList?.map((item, index) => (
          <div className="role-permission-table__row" key={index}>
            <div className="item">{item.function_name}</div>
            <div className="item"><Checkbox checked={isFull(item)} onChange={(e)=>{
              changeRole({
                ...item,
                is_full: e.target.checked ? 1 : 0,
                iskey: 'is_full'
              })
            }} className='rs-checkbox' /></div>
            <div className="item"><Checkbox checked={item.is_view === 1} className='rs-checkbox' onChange={(e)=>{
              changeRole({
                ...item,
                is_view: e.target.checked ? 1 : 0,
              })
            }} /></div>
            <div className="item"><Checkbox checked={item.is_create === 1} className='rs-checkbox' onChange={(e)=>{
              changeRole({
                ...item,
                is_create: e.target.checked ? 1 : 0,
              })
            }} /></div>
            <div className="item"><Checkbox checked={item.is_edit === 1} className='rs-checkbox' onChange={(e)=>{
              changeRole({
                ...item,
                is_edit: e.target.checked ? 1 : 0,
              })
            }} /></div>
            <div className="item"><Checkbox checked={item.is_delete === 1} className='rs-checkbox' onChange={(e)=>{
              changeRole({
                ...item,
                is_delete: e.target.checked ? 1 : 0,
              })
            }} /></div>
          </div>
        ))
      }
      {/* <div className="role-permission-table__row">
        <div className="item">Report</div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
      </div>
      <div className="role-permission-table__row">
        <div className="item">Machine</div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
      </div>
      <div className="role-permission-table__row">
        <div className="item">Member</div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
      </div>
      <div className="role-permission-table__row">
        <div className="item">Voucher</div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
      </div>
      <div className="role-permission-table__row">
        <div className="item">Staff</div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
      </div>
      <div className="role-permission-table__row">
        <div className="item">Store game</div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
      </div>
      <div className="role-permission-table__row">
        <div className="item">Setting</div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
        <div className="item"><Checkbox className='rs-checkbox' /></div>
      </div> */}
    </div>
  )
}

export default RolePermissionTable
