import React, { useEffect, useRef, useState } from 'react';
import { Button, Drawer, Flex, Form, FormInstance, FormProps, Input, Select, SelectProps, notification } from 'antd';
import { ArrowSquareDown, CallCalling, Crown, DirectSend, } from 'iconsax-react';
import { mixGetV1, mixPostV1 } from '../../../services/mixin';

interface Props {
  isShow?: boolean
  onPopupClose?: () => void
  onSuccess?: () => void
}

type FieldType = {
  fullName?: string;
  roleId?: number,
  email?: string;
  phoneNumber?: string;
};

const SettingCreateUserPopup: React.FC<Props> = (props) => {
  const { isShow = false, onPopupClose, onSuccess } = props
  const [open, setOpen] = useState(isShow)
  const [roles, setRoles] = useState([] as any)

  useEffect(() => {
    setOpen(isShow)
  }, [isShow])

  const onClose = () => {
    setOpen(false);
    if (onPopupClose) {
      onPopupClose();
    }
  };

  // FORM SUBMIT
  const formRef = useRef<FormInstance<FieldType>>(null);
  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  };

  // FORM SUBMIT SUCCESSFULLY
  const [confirmLoading, setConfirmLoading] = useState(false)
  const handleOk = async (values: any) => {
    setConfirmLoading(true)

    const params = {
      user_name: values.fullName,
      role_id: values.roleId,
      email: values.email,
      phone_number: values.phoneNumber,
    }
    const res = await mixPostV1('/api/webAdmin/createUser.php', params)

    if (res.status === 1) {
      setConfirmLoading(false)
      onClose()
      openNotification()

      if (formRef.current) {
        formRef.current.resetFields()
      }
      if (onSuccess) {
        onSuccess()
      }
    } else {
      setConfirmLoading(false)
      openNotification('error', res.message)
    }
  }
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    handleOk(values)
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'User created!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  // POPUP FOOTER
  const Footer = (
    <>
      <Button
        className='rs-button default-outlined'
        onClick={onClose}
      >Close</Button>
      <Button
        type='primary'
        loading={confirmLoading}
        className='rs-button'
        onClick={onSubmitForm}
      >Create</Button>
    </>
  )

  const closeIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z" fill="#6B7280"/> </svg>

  type LabelRender = SelectProps['labelRender'];
  const customSelectedLabel: LabelRender = (props) => {
    const { label } = props;

    if (label) {
      return <Flex align='center' gap={8}><Crown size={20} variant="Bulk"/><span>Role (<span className='text-active'>{label}</span>)</span></Flex>;
    }
    return <Flex align='center' gap={8}><Crown size={20} variant="Bulk"/><span>Role</span></Flex>;
  }

  // GET USER ROLES
  const getUserRoles = async () => {
    const res = await mixGetV1('/api/webAdmin/gComboRoleUser.php', {})

    if (res.status === 1) {
      const result: any = []

      res.data.map((item: any) => result.push({
        ...item,
        value: item.role_id,
        label: item.role_name,
      }))

      setRoles(result)
    }
  }
  useEffect(() => {
    getUserRoles()
  }, [])
  // END GET USER ROLES

  const checkPhone = (_: any, value: any) => {
    if (!/^\d{10}$/.test(value) && value) {
      return Promise.reject(new Error('Phone number is not in the correct format!'))
    }
    return Promise.resolve()
  }

  const validateEmail = (_: any, email: any) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (!email) {
      return Promise.reject(new Error('This field must be filled!'))
    }
    if (!regex.test(email)) {
      return Promise.reject(new Error('Please type a valid email!'))
    }
    return Promise.resolve()
  }

  return (
    <>
      { contextHolder }

      <Drawer
        title="Create user"
        rootClassName="rs-drawer w-338 footer-with-2-button"
        open={open}
        footer={Footer}
        closeIcon={closeIcon}
        onClose={onClose}
      >
        <Form
          ref={formRef}
          onFinish={onFinish}
          autoComplete="off"
          className="rs-form"
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item<FieldType>
            label="Name"
            name="fullName"
            rules={[{ required: true, message: 'This field must be filled!' }]}
          >
            <Input
              placeholder="Full name"
              className="rs-input"
            />
          </Form.Item>

          <Form.Item
            label="Role"
            name="roleId"
            rules={[{ required: true, message: 'This field must be selected!' }]}
            initialValue={''}
          >
            <Select
              options={roles}
              placeholder="Select Staff"
              popupMatchSelectWidth={false}
              suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
              className="rs-select"
              popupClassName='rs-select-dropdown'
              labelRender={customSelectedLabel}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="Phone number"
            name="phoneNumber"
            rules={[
              { required: true, message: 'This field must be filled!' },
              { validator: checkPhone },
            ]}
          >
            <Input
              placeholder="Phone number"
              className="rs-input"
              prefix={<CallCalling size={18} variant="Bulk"/>}
              count={{
                show: false,
                max: 10,
                strategy: (txt) => txt.length,
                exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
              }}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="Email"
            name="email"
            rules={[
              { validator: validateEmail },
            ]}
          >
            <Input
              placeholder="Email"
              className="rs-input"
              prefix={<DirectSend size={18} variant="Bulk"/>}
              type='email'
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default SettingCreateUserPopup
