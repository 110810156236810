import React, {useState, useEffect, useRef} from 'react'
import SettingLayout from '../Components/SettingLayout'
import { Button, Flex, Form, Input, FormInstance, notification } from 'antd'
import RolePermissionTable from '../Components/RolePermissionTable'
import { useNavigate } from 'react-router-dom'
import { mixGetV1, mixPostV1 } from '../../../services/mixin'

const RolePermissionAdd:React.FC = () => {
  const navigate = useNavigate()

  const [roleList, setRoleList] = useState<any[]>([])
  let waitx = false
  const getList = async () => {
    if (waitx) {
      return
    }
    waitx = true
    const res = await mixGetV1('/api/webAdmin/gComboFunction.php')
    waitx = false
    if (res.status === 1) {
      const temp = [] as any
      res.data.map((item: any) => {
        temp.push({
          ...item,
          is_full: 0,
          is_view: 0,
          is_create: 0,
          is_edit: 0,
          is_delete: 0
        })
        return true
      })
      setRoleList(temp)
    }
  }
  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const changeRole = (data: any) => {
    let tempData = {...data}
    const temp = [...roleList]
    temp.map((item, index) => {
      if (item.function_id === tempData.function_id) {
        if (tempData.iskey && tempData.iskey === 'is_full') {
          tempData = {
            ...item,
            is_full: tempData.is_full,
            is_view: tempData.is_full,
            is_create: tempData.is_full,
            is_edit: tempData.is_full,
            is_delete: tempData.is_full
          }
          delete tempData.iskey
          temp[index] = tempData
        } else {
          temp[index] = tempData
        }
      }
      return true
    })
    setRoleList(temp)
  }

  const formRef = useRef<FormInstance>(null)
  const onSubmitForm = () => {
    if (formRef && formRef.current) {
      formRef?.current?.submit()
    }
  }
  const onFinish = async (values: any) => {
    // let isChecked = false
    // roleList.forEach(item => {
    //   if (item.is_view !== 0 || item.is_create !== 0 || item.is_edit !== 0 || item.is_delete !== 0) {
    //     isChecked = true
    //   }
    // })
    // console.log(isChecked)
    const res = await mixPostV1('/api/webAdmin/createRoleUserByStore.php', {
      role_name: values.role_name,
      permissionList: JSON.stringify(roleList)
    })
    if (res.status === 1) {
      navigate('/setting/role-permission')
    } else {
      openNotification('error', res.message)
    }
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Role & permission created!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <SettingLayout current="role-permission">
      {contextHolder}
      <div className="setting-content setting-content--update setting-device">
        <div className="mw-687">
          <Form
            ref={formRef}
            name="add-role-form"
            onFinish={onFinish}
            layout='vertical'
            className='rs-form form-gap-12'
          >
            <Form.Item
              name='role_name'
              label="Role name"
              rules={[{ required: true, message: 'Please filled this field' }]}
            >
              <Input
                placeholder='Example: Admin, manager, staff, ...'
                className='rs-input'
              />
            </Form.Item>

            <RolePermissionTable roleList={roleList} changeRole={(data: any) => changeRole(data)} />
          </Form>
        </div>

        <Flex
          gap={24}
          align='center'
        >
          <Button
            className='rs-button default-outlined button-update'
            onClick={() => navigate('/setting/role-permission')}
          >Cancel</Button>
          <Button
            className='rs-button button-update'
            onClick={onSubmitForm}
            type='primary'
          >Create</Button>
        </Flex>
      </div>
    </SettingLayout>
  )
}

export default RolePermissionAdd
