import { Crown } from 'iconsax-react'
import React from 'react'
import './RolePermissionItem.scss'

interface Props {
  title?: string
  editDisabled?: boolean
  deleteDisabled?: boolean
  onEdit?: () => void
  onDelete?: () => void
  onDuplicate?: () => void
}

const editIcon = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.3333 7.16675H6.66667C6.31305 7.16675 5.97391 7.30722 5.72386 7.55727C5.47381 7.80732 5.33333 8.14646 5.33333 8.50008V17.8334C5.33333 18.187 5.47381 18.5262 5.72386 18.7762C5.97391 19.0263 6.31305 19.1667 6.66667 19.1667H16C16.3536 19.1667 16.6928 19.0263 16.9428 18.7762C17.1929 18.5262 17.3333 18.187 17.3333 17.8334V13.1667" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M16.3333 6.16665C16.5986 5.90144 16.9583 5.75244 17.3333 5.75244C17.7084 5.75244 18.0681 5.90144 18.3333 6.16665C18.5985 6.43187 18.7475 6.79158 18.7475 7.16665C18.7475 7.54173 18.5985 7.90144 18.3333 8.16665L12 14.5L9.33333 15.1667L10 12.5L16.3333 6.16665Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </svg>

const deleteIcon = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 8.49992H7.33333M7.33333 8.49992H18M7.33333 8.49992V17.8333C7.33333 18.1869 7.47381 18.526 7.72386 18.7761C7.97391 19.0261 8.31304 19.1666 8.66667 19.1666H15.3333C15.687 19.1666 16.0261 19.0261 16.2761 18.7761C16.5262 18.526 16.6667 18.1869 16.6667 17.8333V8.49992H7.33333ZM9.33333 8.49992V7.16659C9.33333 6.81296 9.47381 6.47382 9.72386 6.22378C9.97391 5.97373 10.313 5.83325 10.6667 5.83325H13.3333C13.687 5.83325 14.0261 5.97373 14.2761 6.22378C14.5262 6.47382 14.6667 6.81296 14.6667 7.16659V8.49992M10.6667 11.8333V15.8333M13.3333 11.8333V15.8333" stroke="#F5222D" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const duplicateIcon = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.33333 14.4999H6.66666C6.31304 14.4999 5.9739 14.3594 5.72385 14.1094C5.4738 13.8593 5.33333 13.5202 5.33333 13.1666V7.16659C5.33333 6.81296 5.4738 6.47382 5.72385 6.22378C5.9739 5.97373 6.31304 5.83325 6.66666 5.83325H12.6667C13.0203 5.83325 13.3594 5.97373 13.6095 6.22378C13.8595 6.47382 14 6.81296 14 7.16659V7.83325M11.3333 10.4999H17.3333C18.0697 10.4999 18.6667 11.0969 18.6667 11.8333V17.8333C18.6667 18.5696 18.0697 19.1666 17.3333 19.1666H11.3333C10.5969 19.1666 10 18.5696 10 17.8333V11.8333C10 11.0969 10.5969 10.4999 11.3333 10.4999Z" stroke="#E6E6E6" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/> </svg>

const RolePermissionItem:React.FC<Props> = (props) => {
  const { title, editDisabled = false, deleteDisabled = false, onEdit, onDelete, onDuplicate } = props

  const handleEdit = () => {
    if (onEdit && !editDisabled) {
      onEdit()
    }
  }

  const handleDelete = () => {
    if (onDelete && !deleteDisabled) {
      onDelete()
    }
  }

  const handleDuplicate = () => {
    if (onDuplicate && !editDisabled) {
      onDuplicate()
    }
  }

  return (
    <div className="role-permission-item">
      <Crown size={18} variant='Bulk' />
      <span className="title">{ title }</span>

      <div className="actions">
        <div
          className={`action-item ${editDisabled ? 'disabled' : ''}`}
          title='Edit'
          onClick={handleEdit}
        >
          { editIcon }
          <span>Edit</span>
        </div>
        <div
          className={`action-item delete ${editDisabled ? 'disabled' : ''}`}
          title='Delete'
          onClick={handleDelete}
        >
          { deleteIcon }
          <span>Delete</span>
        </div>
        <div
          className={`action-item ${editDisabled ? 'disabled' : ''}`}
          title='Duplicate'
          onClick={handleDuplicate}
        >
          { duplicateIcon }
          <span>Duplicate</span>
        </div>
      </div>
    </div>
  )
}

export default RolePermissionItem
