import React, { useEffect, useState } from 'react'
import { Button, Flex, Form, FormProps, InputNumber, notification } from 'antd'
import { Home2 } from 'iconsax-react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { mixPostV1 } from '../../services/mixin'

type FieldType = {
  matchOnly: number,
  matchReading: number,
  matchDoor: number,
}

const SettingType:React.FC = () => {
  // const [loading, setLoading] = useState(false)
  const formRef = React.useRef<any>(null)

  useEffect(() => {
    getSettingTypes()
  }, [])

  const [confirmLoading, setConfirmLoading] = useState(false)
  const onSubmitForm = () => {
    if (formRef && formRef.current) {
      formRef?.current?.submit()
    }
  }
  const onFinish: FormProps<FieldType>["onFinish"] = async (values: any) => {
    setConfirmLoading(false)

    const typeInfo = []
    for (const key in values) {
      typeInfo.push({
        type_id: Number(key),
        income: values[key],
      })
    }

    const typeInfoJSON = JSON.stringify(typeInfo)
    const params = {
      typeInfo: typeInfoJSON
    }
    const res = await mixPostV1('/api/webAdmin/updateSettingTypeStore.php', params)

    if (res.status) {
      setConfirmLoading(false)
      openNotification()
    } else {
      setConfirmLoading(false)
      openNotification('error', 'Something went wrong!')
    }
  }

  // GET TYPES
  const [settingTypes, setSettingTypes] = useState<any>([])
  const getSettingTypes = async () => {
    const res = await mixPostV1('/api/webAdmin/gSettingTypeStore.php', {})

    if (res.status) {
      const dataFields: any = {}
      res.data.map((item: any) => dataFields[item.type_id] = item.income)
      setSettingTypes(res.data)
      formRef?.current.setFieldsValue(dataFields)
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Account updated!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <Layout
        className='match-height'
        contentClassName="setting-layout"
      >
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Setting type', },
          ]}
          title='Setting type'
          description=''
        />

        <div className="setting-content setting-content--update">
          <div className="mw-596">
            <Flex vertical gap={16}>
              <div className='title-12 text-idle'>Edit and click <span className='font-bold'>Update</span> if you want to change anything</div>

              <Form
                ref={formRef}
                autoComplete="off"
                className="rs-form form-setting-type"
                layout='vertical'
                onFinish={onFinish}
              >
                {
                  settingTypes.length > 0 && settingTypes.map((item: any) => (
                    <Flex gap={12} key={item.type_id}>
                      <span className='label'>{item.type_name}</span>
                      <Form.Item
                        name={item.type_id.toString()}
                        rules={[
                          { required: true, message: 'This field must be filled!' }
                        ]}
                      >
                        <InputNumber
                          className="rs-input"
                          min={1}
                        />
                      </Form.Item>
                    </Flex>
                  ))
                }
                {/* <Flex gap={12}>
                  <span className='label'>Match only</span>
                  <Form.Item
                    name="matchOnly"
                    rules={[
                      { required: true, message: 'This field must be filled!' }
                    ]}
                  >
                    <InputNumber
                      className="rs-input"
                    />
                  </Form.Item>
                </Flex>

                <Flex gap={12}>
                  <span className='label'>Match reading</span>
                  <Form.Item
                    name="matchReading"
                    rules={[
                      { required: true, message: 'This field must be filled!' }
                    ]}
                  >
                    <InputNumber
                      className="rs-input"
                    />
                  </Form.Item>
                </Flex>

                <Flex gap={12}>
                  <span className='label'>Match door</span>
                  <Form.Item
                    name="matchDoor"
                    rules={[
                      { required: true, message: 'This field must be filled!' }
                    ]}
                  >
                    <InputNumber
                      className="rs-input"
                    />
                  </Form.Item>
                </Flex>

                <Flex gap={12}>
                  <span className='label'>Full service</span>
                  <Form.Item
                    name="fullService"
                    rules={[
                      { required: true, message: 'This field must be filled!' }
                    ]}
                  >
                    <InputNumber
                      className="rs-input"
                    />
                  </Form.Item>
                </Flex> */}
              </Form>
            </Flex>
          </div>

          <Button
            className='rs-button button-update'
            type='primary'
            loading={confirmLoading}
            onClick={onSubmitForm}
          >Update</Button>
        </div>
      </Layout>
    </>
  )
}

export default SettingType
