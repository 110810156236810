import React from 'react'
import './Results.scss'
import { Button } from 'antd'
import { Send2 } from 'iconsax-react'
import GameEmptyResult from './EmptyResult'
import GamePlayer from './Player'
import RandomInputItem from './RandomInputItem'

interface Props {
  title?: string,
  userAvatar?: string,
  userName?: string,
  userId?: string,
  type?: string,
  empty?: boolean,
  playing?: boolean,
  winners?: Array<any>,
  onSendSMS?: () => void
}

const Results: React.FC<Props> = (props) => {
  const {
    title,
    type = 'lucky-game',
    userAvatar ='/images/avatar/avatar1-40x40.png',
    // userName = 'Jane Cooper',
    // userId = 'G321',
    empty = true,
    playing = true,
    winners = [],
    onSendSMS
  } = props

  const sendSMS = () => {
    if (onSendSMS) {
      onSendSMS()
    }
  }

  return (
    <div className="game-results">
      <div className="game-results__header">{ title }</div>

      <div className={`game-results__content ${type}`}>
        {
          empty
            ?
              <GameEmptyResult />
            :
              type === 'lucky-game'
                ?
                  winners.map((item: any) => (
                    <GamePlayer
                      key={item.id}
                      id={item.id}
                      avatar={item.avatar || userAvatar}
                      name={item.name}
                      count={item.total}
                    />
                  ))
                :
                  winners.map((item: any) => (
                    <RandomInputItem key={item.id} text={item.text} showDelete={false} />
                  ))
        }
      </div>

      {
        (type === 'lucky-game' && !empty) &&
          <div className="game-results__footer">
            <Button
              icon={<Send2 size="20" variant="Bulk"/>}
              type='primary'
              block
              disabled={playing || empty}
              className='rs-button primary-outlined size-sm'
              onClick={sendSMS}
            >Send message</Button>
          </div>
      }
    </div>
  )
}

export default Results
