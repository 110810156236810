import React, { useEffect, useState } from 'react'
import SettingLayout from './Components/SettingLayout'
import { Button, Flex, Tabs, TabsProps } from 'antd';
import { CalendarAdd, I24Support } from 'iconsax-react';
import AlwaysOpen from './TabShift/AlwaysOpen';
import SelectedDay from './TabShift/SelectedDay';
import './Styles/Shift.scss'
import { mixGetV1 } from '../../services/mixin';
import dayjs from 'dayjs';

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const SettingShift:React.FC = () => {
  const onChange = (key: string) => {
    setTypeWork(key)
    setIsSubmit(0)
  };

  const [dataAlwaysOpen, setDataAlwaysOpen] = useState<any>([])
  const [typeWork, setTypeWork] = useState<any>(null)
  const [listDay, setListDay] = useState<any>([])
  let waitx = false

  const gSettingShiftByStore = async () => {
    if (waitx) { return }
    waitx = true
    const res = await mixGetV1('/api/webAdmin/gSettingShiftByStore.php')
    waitx = false

    if (res.status === 1) {
      const dataAlwayOpen = res.data_alway_open || []
      const temp = [] as any
      dataAlwayOpen.map((item: any, index: number) => {
        temp.push({
          shift_name: `Shift ${index + 1}`,
          time: [dayjs(item.from_time, 'HH:mm'), dayjs(item.to_time, 'HH:mm')],
          from_time: dayjs(item.from_time, 'HH:mm'),
          to_time: dayjs(item.to_time, 'HH:mm'),
        })
        return true
      })

      const tempday = [] as any
      const dataDay = res.data_day || []
      res.listDay.map((item: any) => {
        const dayx = dataDay.find((e: any) => e.id === item.id)
        const listTime = [] as any
        if (dayx) {
          dayx.listShift.map((e: any, index: number) => {
            listTime.push({
              shift_name: `Shift ${index + 1}`,
              time: [dayjs(e.from_time, 'HH:mm'), dayjs(e.to_time, 'HH:mm')],
              from_time: dayjs(e.from_time, 'HH:mm'),
              to_time: dayjs(e.to_time, 'HH:mm'),
            })
            return true
          })
        }
        tempday.push({
          ...item,
          listShift: listTime
        })
        return true
      })
      setDataAlwaysOpen(temp)
      setTypeWork(res.type_working)
      setListDay(tempday)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { gSettingShiftByStore() }, [])

  const [isSubmit, setIsSubmit] = useState<any>(0)
  useEffect(() => {
    if (isSubmit === 1) {
      gSettingShiftByStore()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit])

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: (
        <div className="title-tab">
          <div className="icon-svg"><I24Support size="18" color="#999999" variant="Bulk" /></div>
          <div className="text">Always Open (24/7)</div>
        </div>
      ),
      children: (<AlwaysOpen dataAlwaysOpen={dataAlwaysOpen} typeWork={typeWork} isSubmit={isSubmit} />),
    },
    {
      key: '1',
      label: (
        <div className="title-tab">
          <div className="icon-svg"><CalendarAdd size="18" color="#999999" variant="Bulk" /></div>
          <div className="text">Selected day</div>
        </div>
      ),
      children: (<SelectedDay listDay={listDay} typeWork={typeWork} isSubmit={isSubmit} />),
    },
  ];

  return (
    <SettingLayout current="shift">
      <div className="setting-content setting-content--update setting-shift tab-setting">
        <div className="mw-687 shift-content">
          <Flex
            vertical
            gap={8}
          >
            <div className="title-12 text-idle font-bold">Working day</div>
            {typeWork !== null &&
              <Tabs activeKey={`${typeWork}`} items={items} onChange={onChange} />
            }
          </Flex>
        </div>

        <Button
          className='rs-button button-update'
          type='primary'
          disabled={settingRole && settingRole.is_edit === 0}
          onClick={() => setIsSubmit(isSubmit + 1)}
        >Update</Button>
      </div>
    </SettingLayout>
  )
}

export default SettingShift
