import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Badge, Button, Flex, notification, PaginationProps, Select, SelectProps, Table, TableColumnsType } from 'antd'
import { ArrowSquareDown, Category2, DocumentDownload, ElementPlus, Flag, Gameboy, Home2, Profile2User, SearchZoomIn } from 'iconsax-react'
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons'
import DeleteModal from '../../components/Modal/DeleteModal'
import CreateMyStorePopup from './Popup/CreateMyStore'
import ViewMyStorePopup from './Popup/ViewMyStore'
import UpdateMyStorePopup from './Popup/UpdateMyStore'
import CreateOwnerStorePopup from './Popup/CreateOwnerStore'
import ViewOwnerStorePopup from './Popup/ViewOwnerStore'
import UpdateOwnerStorePopup from './Popup/UpdateOwnerStore'
import { mixGetV1, mixPostV1 } from '../../services/mixin'
import { storeStatus } from '../../services/mixOption'
import * as XLSX from 'xlsx'
import DevicesOfStoreModal from './Popup/DevicesOfStoreModal'

const Airplay = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.16666 14.1667H3.33332C2.8913 14.1667 2.46737 13.9911 2.15481 13.6785C1.84225 13.366 1.66666 12.942 1.66666 12.5V4.16667C1.66666 3.72464 1.84225 3.30072 2.15481 2.98816C2.46737 2.67559 2.8913 2.5 3.33332 2.5H16.6667C17.1087 2.5 17.5326 2.67559 17.8452 2.98816C18.1577 3.30072 18.3333 3.72464 18.3333 4.16667V12.5C18.3333 12.942 18.1577 13.366 17.8452 13.6785C17.5326 13.9911 17.1087 14.1667 16.6667 14.1667H15.8333M9.99999 12.5L14.1667 17.5H5.83332L9.99999 12.5Z" stroke="#999999" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/></svg>

type LabelRender = SelectProps['labelRender']
const customSelectedLabel: LabelRender = (props) => {
  const { label, value } = props;

  if (value && label) {
    return <Flex align='center' gap={8}><Category2 size={18} variant="Bulk" style={{ flexShrink: 0 }}/><span>{ label }</span></Flex>
  }
  return <Flex align='center' gap={8}><Category2 size={18} variant="Bulk" style={{ flexShrink: 0 }}/><span>Type</span></Flex>
}
const customSelectedLabel2: LabelRender = (props) => {
  const { label, value } = props;

  if (value && label) {
    return <Flex align='center' gap={8}><SearchZoomIn size={18} variant="Bulk" style={{ flexShrink: 0 }}/><span>{ label }</span></Flex>
  }
  return <Flex align='center' gap={8}><SearchZoomIn size={18} variant="Bulk" style={{ flexShrink: 0 }}/><span>Select Owner</span></Flex>
}
const customSelectedLabel3: LabelRender = (props) => {
  const { label, value } = props;

  if (value && label) {
    return <Flex align='center' gap={8}><Flag size={18} variant="Bulk" style={{ flexShrink: 0 }}/><span>{ label }</span></Flex>
  }
  return <Flex align='center' gap={8}><Flag size={18} variant="Bulk" style={{ flexShrink: 0 }}/><span>Status</span></Flex>
}

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const StoreList: React.FC = () => {
  const [showDeleteStorePopup, setShowDeleteStorePopup] = useState(false)
  const [createMyStoreShow, setCreateMyStoreShow] = useState(false)
  const [viewMyStoreShow, setViewMyStoreShow] = useState(false)
  const [updateMyStoreShow, setUpdateMyStoreShow] = useState(false)
  const [createOwnerStoreShow, setCreateOwnerStoreShow] = useState(false)
  const [viewOwnerStoreShow, setViewOwnerStoreShow] = useState(false)
  const [updateOwnerStoreShow, setUpdateOwnerStoreShow] = useState(false)
  const [selectedId, setSelectedId] = useState<any>()

  // role_id: 1:admin, 2:owner, 3:sales, 4:user
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const roleId: any = user.role_id

  const columns: TableColumnsType = [
    {
      title: '',
      key: 'actions',
      fixed: 'left',
      className: 'actions-sticky actions-sticky--left',
      render: (record) => (
        <Flex align='center' gap={4}>
          <Button
            icon={<Gameboy variant="Bulk"/>}
            className="rs-button"
            onClick={()=> {
              setSelectedId(record.store_id)
              setShowStoreDevicesModal(true)
            }}
          />

          <Button
            icon={<DeleteOutlined />}
            className="rs-button delete"
            onClick={()=> {
              setSelectedId(record.store_id)
              setShowDeleteStorePopup(true)
            }}
          />

          <Button
            icon={Airplay}
            className="rs-button edit"
            onClick={()=> {
              setSelectedId(record.store_id)
              // record.store === 'My store' ? setViewMyStoreShow(true) : setViewOwnerStoreShow(true)
              record.store === 'My store' ? setUpdateMyStoreShow(true) : setUpdateOwnerStoreShow(true)
            }}
          />
        </Flex>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Badge status={storeStatus(status).color} text={ record.status_name } className='rs-badge' />
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type_id',
      key: 'type_id',
      render: (type_id, record) => (
        // <Badge status={storeType(type_id).color} text={ record.type_name } className='rs-badge background' />
        <Badge status={type_id === 1 ? 'warning' : 'default'} text={ record.type_name } className='rs-badge background' />
      ),
    },
    {
      title: 'Store ID',
      dataIndex: 'store_id',
      key: 'store_id',
    },
    {
      title: 'Store',
      dataIndex: 'store',
      key: 'store',
    },
    {
      title: 'Store name',
      dataIndex: 'store_name',
      key: 'store_name',
    },
    {
      title: 'Owner name',
      dataIndex: 'owner_name',
      key: 'owner_name',
      // render: (text, record) => (
      //   <Flex align='center' gap={8}>
      //     <Avatar size={40} src={record.ownerAvatar} />
      //     <span>{ text }</span>
      //   </Flex>
      // )
    },
    {
      title: 'Owner Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Owner email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created at',
      dataIndex: 'create_at',
      key: 'create_at',
    },
    {
      title: 'Seller name',
      dataIndex: 'seller_name',
      key: 'seller_name',
      render: (text, record) => (
        <Flex align='center' gap={8}>
          {/* <Avatar size={40} src={record.sellerAvatar} /> */}
          <Flex vertical>
            <span>{ text }</span>
            <span className="title-12 neutral-gray-500">Seller ID { record.seller_id }</span>
          </Flex>
        </Flex>
      )
    },
    {
      title: 'Sent SMS',
      dataIndex: 'sentSMS',
      key: 'sentSMS',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ]

  useEffect(() => {
    getItems()
    getOwners()
    getStoreTypes()
    getStatusStore()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // GET OWNERS
  const [owners, setOwners] = useState([] as any)
  const getOwners = async () => {
    try {
      const res = await mixPostV1('/api/webAdmin/gComboOwner.php', {})

      if (res.status === 1) {
        const result: any = []
        res.data.map((item: any) => result.push({
          ...item,
          value: item.id,
          label: item.owner_name,
        }))
        setOwners(result)
      }

    } catch (error) { }
  }
  // END GET OWNERS

  // GET SERVICE TYPES
  const [serviceTypes, setServiceTypes] = useState([] as any)
  const getStoreTypes = async () => {
    try {
      const res = await mixGetV1('/api/webAdmin/gComboTypeStore.php')

      if (res.status === 1) {
        const result: any = []
        res.data.map((item: any) => result.push({
          ...item,
          value: item.type_id,
          label: item.type_name,
        }))
        setServiceTypes(result)
      }
    } catch (error) { }
  }
  // END GET SERVICE TYPES

  // GET STATUS STORE
  const [statusStore, setStatusStore] = useState([] as any)
  const getStatusStore = async () => {
    try {
      const res = await mixGetV1('/api/webAdmin/gComboStatusStore.php', { })

      if (res.status === 1) {
        const result: any = []
        res.data.map((item: any) => result.push({
          ...item,
          value: item.status,
          label: item.status_name,
        }))
        setStatusStore(result)
      }
    } catch (error) { }
  }
  // END GET STATUS STORE

  // GET LIST
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })
  const [tableLoading, setTableLoading] = useState(false)

  const [params, setParams] = useState<{
    limit: number,
    page_number: number,
    searchOwnerId?: number | string,
    searchStatus?: number | string,
    searchType?: number | string,
  }>({
    limit: 10,
    page_number: 1,
    searchOwnerId: '',
    searchStatus: '',
    searchType: ''
  })

  const getItems = async () => {
    setTableLoading(true)
    let res

    if (roleId === 3) {
      res = await mixPostV1('/api/webAdmin/gListStoreSeller.php', params)
    } else {
      res = await mixPostV1('/api/webAdmin/gListStoreOwner.php', params)
    }

    if (res.status === 1) {
      setItems({
        ...items,
        list: res.data,
        total: res.total,
      })
      setTableLoading(false)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onTableChange = (data: any) => {
    setParams({
      ...params,
      page_number: data.current,
      limit: data.pageSize,
    })
  }
  // END GET LIST

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }

    try {
      const res = await mixPostV1('/api/webAdmin/gListStoreOwner.php', customParams)

      if (res.status) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Status': item.status_name,
        // 'Type': item.type_name,
        'Store ID': item.store_id,
        'Store': item.store,
        'Store name': item.store_name,
        'Owner name': item.owner_name,
        'Owner phone': item.phone_number,
        'Owner email': item.email,
        'Create at': item.create_at,
        'Seller name': item.seller_name,
        'Sent SMS': item.sent_sms || '',
        'Address': item.address,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Live Store"}.xlsx`)
      return true // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  // STORE'S DEVICES MODAL
  const [showStoreDevicesModal, setShowStoreDevicesModal] = useState(false)

  return (
    <>
      { contextHolder }

      <Layout contentClassName="store-list-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Store List', },
            { title: 'Live store', },
          ]}
          title='Live store'
          description=''
        />

        <div className="table-filter-block">
          <Select
            allowClear={params.searchOwnerId !== ''}
            value={params.searchOwnerId}
            options={owners}
            placeholder="Select Owner"
            popupMatchSelectWidth={false}
            suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
            className="rs-select mw-288"
            popupClassName='rs-select-dropdown'
            labelRender={customSelectedLabel2}
            onChange={(value) => setParams({ ...params, searchOwnerId: value ? Number(value) : '' })}
          />

          <Select
            allowClear={params.searchType !== ''}
            value={params.searchType}
            options={serviceTypes}
            placeholder="Type"
            popupMatchSelectWidth={false}
            suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
            labelRender={customSelectedLabel}
            className="rs-select mw-173"
            popupClassName='rs-select-dropdown'
            onChange={(value) => setParams({ ...params, searchType: value ? Number(value) : '' })}
          />

          <Select
            allowClear={params.searchStatus !== ''}
            value={params.searchStatus}
            options={statusStore}
            placeholder="Status"
            popupMatchSelectWidth={false}
            suffixIcon={<ArrowSquareDown size={20} variant="Bulk" />}
            labelRender={customSelectedLabel3}
            className="rs-select mw-173"
            popupClassName='rs-select-dropdown'
            onChange={(value) => setParams({ ...params, searchStatus: typeof value !== 'undefined' ? Number(value) : '' })}
          />

          <Button
            icon={<DocumentDownload size={20} variant="Bulk"/>}
            className="rs-button mis-auto"
            onClick={getItemsByExcel}
          >Export</Button>

          {
            roleId !== 3 && (
              <Button
                icon={<ElementPlus size={20} variant="Bulk"/>}
                className="rs-button"
                onClick={() => setCreateMyStoreShow(true)}
              >Create my store</Button>
            )
          }

          <Button
            icon={<Profile2User size={20} variant="Bulk"/>}
            className="rs-button"
            onClick={() => setCreateOwnerStoreShow(true)}
          >Create owner store</Button>
        </div>

        <Table
          columns={columns}
          dataSource={items.list}
          className="rs-table table-text-nowrap match-height"
          loading={tableLoading}
          rowKey="store_id"
          scroll={{
            x: true,
          }}
          pagination={{
            pageSize: params.limit,
            position: ['bottomCenter'],
            showSizeChanger: true,
            showTitle: false,
            total: items.total,
            itemRender: customPrevNext,
            onChange: (e) => onPageChange(e),
          }}
          onChange={onTableChange}
        />

        {/* DELETE STORE */}
        <DeleteModal
          isShow={showDeleteStorePopup}
          content='Are you sure you want to delete this Store?'
          message='Store deleted!'
          deleteApi='/api/webAdmin/deleteStoreOwner.php'
          deleteId={selectedId}
          deleteKey='store_id'
          onModalClose={() => setShowDeleteStorePopup(false)}
          onModalSubmit={() => {
            getItems()
            setShowDeleteStorePopup(false)
          }}
        />

        {/* CREATE MY STORE */}
        <CreateMyStorePopup
          isShow={createMyStoreShow}
          onPopupClose={() => setCreateMyStoreShow(false)}
          onSuccess={() => {
            getItems()
            setCreateMyStoreShow(false)
          }}
        />

        {/* VIEW MY STORE */}
        <ViewMyStorePopup
          id={selectedId}
          isShow={viewMyStoreShow}
          onPopupClose={() => setViewMyStoreShow(false)}
          onOpenEdit={() => setUpdateMyStoreShow(true)}
          onSuccess={() => {
            getItems()
            setUpdateMyStoreShow(false)
          }}
        />

        {/* UPDATE MY STORE */}
        <UpdateMyStorePopup
          id={selectedId}
          isShow={updateMyStoreShow}
          onPopupClose={() => setUpdateMyStoreShow(false)}
          onSuccess={() => {
            getItems()
            setUpdateMyStoreShow(false)
          }}
        />

        {/* CREATE OWNER STORE */}
        <CreateOwnerStorePopup
          isShow={createOwnerStoreShow}
          onPopupClose={() => setCreateOwnerStoreShow(false)}
          onSuccess={() => {
            getItems()
            setCreateOwnerStoreShow(false)
          }}
        />

        {/* VIEW OWNER STORE */}
        <ViewOwnerStorePopup
          id={selectedId}
          isShow={viewOwnerStoreShow}
          onPopupClose={() => setViewOwnerStoreShow(false)}
          onOpenEdit={() => setUpdateOwnerStoreShow(true)}
          onSuccess={() => {
            getItems()
            setUpdateMyStoreShow(false)
          }}
        />

        {/* UPDATE OWNER STORE */}
        <UpdateOwnerStorePopup
          id={selectedId}
          isShow={updateOwnerStoreShow}
          onPopupClose={() => setUpdateOwnerStoreShow(false)}
          onSuccess={() => {
            getItems()
            setUpdateOwnerStoreShow(false)
          }}
        />

        {/* STORE'S DEVICES MODAL */}
        <DevicesOfStoreModal
          storeId={selectedId}
          show={showStoreDevicesModal}
          onModalClose={() => setShowStoreDevicesModal(false)}
        />
      </Layout>
    </>
  )
}

export default StoreList
