import React, { useEffect, useState } from 'react'
import SettingLayout from './Components/SettingLayout'
import { Button, Flex, notification } from 'antd'
import { AddSquare } from 'iconsax-react'
import RolePermissionItem from './Components/RolePermissionItem'
import { useNavigate } from 'react-router-dom'
import DeleteModal from '../../components/Modal/DeleteModal'
import { mixGetV1, mixPostV1 } from '../../services/mixin'

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const SettingDevice:React.FC = () => {
  const navigate = useNavigate()
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()

  const [roleList, setRoleList] = useState<any[]>([])
  let waitx = false
  const getList = async () => {
    if (waitx) {
      return
    }
    waitx = true
    const res = await mixGetV1('/api/webAdmin/gComboRoleUser.php')
    waitx = false
    if (res.status === 1) {
      setRoleList(res.data)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  let waitx1 = false
  const onDuplicate = async (item: any) => {
    if (waitx1) {
      return
    }
    waitx1 = true
    const res = await mixGetV1('/api/webAdmin/gInfoPermissionByRole.php', {role_id: item.role_id})
    if (res.status === 1) {
      const res2 = await mixPostV1('/api/webAdmin/createRoleUserByStore.php', {
        role_name: res.data.role_name + ' (Copy)',
        permissionList: JSON.stringify(res.data.permission)
      })
      if (res2.status === 1) {
        openNotification()
        getList()
        waitx1 = false
      } else {
        openNotification('error', res2.message)
        waitx1 = false
      }
    } else {
      waitx1 = false
      openNotification('error', 'Role & permission duplicated failed!')
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Role & permission duplicated!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <SettingLayout current="role-permission">
      {contextHolder}
      <div className="setting-content setting-content--update setting-device">
        <div className="mw-687">
          <Flex
            vertical
            gap={8}
          >
            <div className="title-12 text-idle font-bold">Role list</div>

            <Button
              type='primary'
              icon={<AddSquare size="20" variant="Bulk" />}
              className='rs-button button-duplicate primary-outlined'
              disabled={settingRole && settingRole.is_create === 0}
              onClick={() => navigate('/setting/role-permission/add')}
            >Add new</Button>

            {
              roleList.map((item, index) => (
                <RolePermissionItem
                  key={index}
                  title={item.role_name}
                  editDisabled={settingRole && settingRole.is_edit === 0}
                  deleteDisabled={settingRole && settingRole.is_delete === 0}
                  onEdit={() => navigate(`/setting/role-permission/${item.role_id}`)}
                  onDelete={() => {
                    setSelectedId(item.role_id)
                    setShowDeletePopup(true)
                  }}
                  onDuplicate={() => {onDuplicate(item)}}
                />
              ))
            }

            {/* <RolePermissionItem
              title='Staff'
              onEdit={() => navigate('/setting/role-permission/1')}
              onDelete={() => {
                setSelectedId(1)
                setShowDeletePopup(true)
              }}
            />
            <RolePermissionItem
              title='Manager'
              onEdit={() => navigate('/setting/role-permission/2')}
              onDelete={() => {
                setSelectedId(2)
                setShowDeletePopup(true)
              }}
            /> */}
          </Flex>
        </div>
      </div>

      <DeleteModal
        isShow={showDeletePopup}
        content='All accounts holding this role will be set back to their original, default role.'
        message='Role deleted!'
        yesText='Yes, Delete permanently'
        deleteApi='/api/webAdmin/deleteRoleById.php'
        deleteId={selectedId}
        deleteKey='role_id'
        onModalClose={() => setShowDeletePopup(false)}
        onModalSubmit={() => {
          getList()
          setShowDeletePopup(false)
        }}
      />
    </SettingLayout>
  )
}

export default SettingDevice
