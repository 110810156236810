import React, { useEffect, useState } from 'react'
import SettingLayout from './Components/SettingLayout'
import { Button, Flex, Form, Input, notification } from 'antd'
import { Code1, DirectSend, Lock, ShieldTick } from 'iconsax-react'
import { mixPostV1 } from '../../services/mixin'

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const SettingMyAccount:React.FC = () => {
  const formRef = React.useRef<any>(null)
  const formPasswordRef = React.useRef<any>(null)
  const formPasscodeRef = React.useRef<any>(null)
  const [passwordLengthAccept, setPasswordLengthAccept] = useState(false)

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current?.setFieldValue('email', user.email)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const onPasswordFinish = async (values: any) => {
    const res = await mixPostV1('/api/webAdmin/editMyAccountPassword.php', values)
    if (res.status) {
      openNotification('success', 'Password updated!')
      if (formPasswordRef && formPasswordRef.current) {
        formPasswordRef.current.resetFields()
      }
    } else {
      openNotification('error', res.message)
    }
  }

  const onPasscodeFinish = async (values: any) => {
    const res = await mixPostV1('/api/webAdmin/editMyAccountPassCode.php', values)
    if (res.status) {
      openNotification('success', 'Passcode updated!')
      if (formPasscodeRef && formPasscodeRef.current) {
        formPasscodeRef.current.resetFields()
      }
    } else {
      openNotification('error', res.message)
    }
  }

  const onValuesChange = (changeValues: any, allValues: any) => {
    const { newPassword } = allValues

    if (newPassword) {
      if (newPassword.length >= 8 && newPassword.length <= 15) {
        setPasswordLengthAccept(true)
      } else {
        setPasswordLengthAccept(false)
      }
    } else {
      setPasswordLengthAccept(false)
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Account updated!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <SettingLayout current="my-account">
        <div className="setting-content setting-content--update setting-my-account">
          <div className="mw-596">
            <div className='title-12 text-idle'>Edit and click <span className='font-bold'>Update</span> if you want to change anything</div>
            <Form
              ref={formRef}
              autoComplete="off"
              className="rs-form"
              layout="vertical"
            >
              <Form.Item
                name="email"
                label="Email"
              >
                <Input
                  placeholder="Email"
                  className="rs-input"
                  // disabled={true}
                  readOnly
                  prefix={<DirectSend size={18} variant="Bulk" />}
                />
              </Form.Item>
            </Form>
          </div>

          <Form
            ref={formPasswordRef}
            autoComplete="off"
            className="rs-form form-password-passcode"
            layout="vertical"
            onFinish={onPasswordFinish}
            onValuesChange={onValuesChange}
          >
            <Flex gap={12}>
              <Form.Item
                name="oldPassword"
                label="Old password"
                rules={[
                  {required: true, message: 'This field must be filled!'}
                ]}
                className='w-full'
              >
                <Input
                  placeholder="Old password"
                  className="rs-input"
                  type='password'
                  disabled={settingRole && settingRole.is_edit === 0}
                  prefix={<Lock size={18} variant="Bulk" />}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="New password"
                rules={[
                  {required: true, message: 'This field must be filled!'},
                  { min: 8, message: 'Password must be between 8 and 15 characters in length.' },
                  { max: 15, message: 'Password must be between 8 and 15 characters in length.' }
                ]}
                className='w-full'
              >
                <Input
                  placeholder="New password"
                  className="rs-input"
                  type='password'
                  disabled={settingRole && settingRole.is_edit === 0}
                  prefix={<Lock size={18} variant="Bulk" />}
                />
              </Form.Item>
              <Form.Item label="&nbsp;">
                <Button
                  className='rs-button button-update'
                  type='primary'
                  disabled={settingRole && settingRole.is_create === 0}
                  onClick={() => formPasswordRef.current.submit()}
                >Update</Button>
              </Form.Item>
            </Flex>
          </Form>

          <Form
            ref={formPasscodeRef}
            autoComplete="off"
            className="rs-form form-password-passcode"
            layout="vertical"
            onFinish={onPasscodeFinish}
          >
            <Flex gap={12}>
              <Form.Item
                name="oldPasscode"
                label="Old passcode"
                rules={[
                  {required: true, message: 'This field must be filled!'},
                  {
                    type: 'number',
                    message: 'Please type number.',
                    transform(value) {
                      return Number(value)
                    },
                  },
                ]}
                className='w-full'
              >
                <Input
                  placeholder="Old passcode"
                  className="rs-input"
                  disabled={settingRole && settingRole.is_edit === 0}
                  prefix={<Code1 size={18} variant="Bulk" />}
                  count={{
                    show: false,
                    max: 4,
                    strategy: (txt) => txt.length,
                    exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
                  }}
                />
              </Form.Item>

              <Form.Item
                name="newPasscode"
                label="New passcode"
                rules={[
                  {required: true, message: 'This field must be filled!'},
                  {
                    type: 'number',
                    message: 'Please type number.',
                    transform(value) {
                      return Number(value)
                    },
                  },
                ]}
                className='w-full'
              >
                <Input
                  placeholder="New passcode"
                  className="rs-input"
                  disabled={settingRole && settingRole.is_edit === 0}
                  prefix={<Code1 size={18} variant="Bulk" />}
                  count={{
                    show: false,
                    max: 4,
                    strategy: (txt) => txt.length,
                    exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
                  }}
                />
              </Form.Item>

              <Form.Item label="&nbsp;">
                <Button
                  className='rs-button button-update'
                  type='primary'
                  disabled={settingRole && settingRole.is_create === 0}
                  onClick={() => formPasscodeRef.current.submit()}
                >Update</Button>
              </Form.Item>
            </Flex>
          </Form>

          <div className="flex flex-vertical gap-12">
            <div className="flex gap-8">
              <ShieldTick size={20} variant='Bulk' className={passwordLengthAccept ? 'text-success' : 'text-idle'} />
              <span className='text-idle'>Password must be between 8 and 15 characters in length</span>
            </div>
          </div>

          {/* <Button
            className='rs-button button-update'
            type='primary'
            disabled={settingRole && settingRole.is_create === 0}
            onClick={() => formRef.current.submit()}
          >Update</Button> */}
        </div>
      </SettingLayout>
    </>
  )
}

export default SettingMyAccount
